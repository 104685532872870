import React, { useState, useEffect, useContext } from 'react'

import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { chat } from 'api/chat'
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemIcon,
  CardActionArea,
  Card,
} from '@mui/material'
import { Button } from 'components/Button/Button'
import { Header } from 'components/Workflow/WorkflowContainerHeader'
import vocabulary from 'vocabulary/vocabulary'
import GlobalContext from '../../../GlobalContext'
import { useTheme } from '@mui/material/styles'
import { useRef } from 'react'
import { Robot, User } from '@phosphor-icons/react'
import { TelegramLogo, WhatsappLogo, At } from '@phosphor-icons/react'
import { Typography } from '../../../../node_modules/@mui/material/index'

export const Chat = () => {
  const { langSelected, state } = useContext(GlobalContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const isOpen = searchParams.get('open') === 'chat'
  const [threadId, setThreadId] = useState(null)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')

  useEffect(() => {
    if (isOpen && !threadId) {
      // Create a new chat thread when the chat opens
      chat
        .post('/thread')
        .then(response => {
          setThreadId(response.data.id)
        })
        .catch(error => {
          console.error('Error creating thread:', error)
        })
    }
  }, [isOpen])

  const handleSendMessage = () => {
    if (!newMessage.trim()) return

    const userMessage = { role: 'user', content: newMessage }
    setMessages([...messages, userMessage])
    setNewMessage('')
    setIsLoading(true)

    chat
      .post(`/thread/${threadId}/messages`, { message: newMessage })
      .then(response => {
        const { data } = response

        setIsLoading(false)

        const formattedText = data.content[0].text.value
          .split(/(\d+\.\s+)/)
          .filter(Boolean)
          .map((part, index) => (index % 2 === 0 ? part : `\n${part.trim()}`))
          .join('')

        let cleanedText = formattedText.replace(/【\d+:\d+†[^】]+】/g, '')
        cleanedText = cleanedText.replace(/\[app\.consul\.online\]/g, '')
        cleanedText = cleanedText.replace(/app\./g, '')
        cleanedText = cleanedText.replace(
          /\(https:\/\/app\.consul\.online\)/g,
          '',
        )
        const assistantMessage = {
          role: data.role,
          content: cleanedText,
        }
        setMessages([...messages, userMessage, assistantMessage])
      })
      .catch(error => {
        console.error('Error sending message:', error)
      })
  }

  const scrollContainerRef = useRef()

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current?.scrollHeight
    }
  })
  const theme = useTheme()

  const handleClose = () => {
    chat
      .post(`/thread/${threadId}/close`)
      .then(() => {
        // setOpen(false);
        setThreadId(null)
        setMessages([])
      })
      .catch(error => {
        console.error('Error closing thread:', error)
      })

    navigate(location.pathname)
  }

  return (
    <DialogModalWindow sx={{ maxHeight: '600px' }} open={isOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: '1 0 auto',
          maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            mx: 4,
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header onClose={handleClose} onBackward={handleClose} />
          <Box
            sx={{
              display: 'flex',
              mx: 4,
              mb: 4,
              flexDirection: 'column',
              cursor: 'pointer',
            }}
          >
            <Typography component='div' variant='titleSmall'>
              {vocabulary.contactUs[langSelected]}
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
              <Card
                sx={{
                  width: '32px',
                  backgroundColor: theme.palette.primary.light,
                  mx: 1,
                }}
              >
                <CardActionArea>
                  <TelegramLogo
                    size={32}
                    style={{ color: theme.palette.primary.onTertiaryContainer }}
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_TELEGRAM_CONNECTION
                    }}
                  />
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  width: '32px',
                  backgroundColor: theme.palette.primary.light,
                  mx: 1,
                }}
              >
                <CardActionArea>
                  <WhatsappLogo
                    size={32}
                    style={{ color: theme.palette.primary.onTertiaryContainer }}
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_WHATSAPP_CONNECTION
                    }}
                  />
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  width: '32px',
                  backgroundColor: theme.palette.primary.light,
                  mx: 1,
                }}
              >
                <CardActionArea>
                  <At
                    size={32}
                    style={{ color: theme.palette.primary.onTertiaryContainer }}
                    onClick={() => {
                      window.location.href = process.env.REACT_APP_MAIL_SUPPORT
                    }}
                  />
                </CardActionArea>
              </Card>
            </Box>
          </Box>
          <List
            ref={scrollContainerRef}
            sx={{
              border: '1px solid #E0E0E0',
              borderRadius: '4px',
              flex: '1 1 auto',
              pb: 4,
              wordWrap: 'break-word',
              overflowY: 'auto',
              maxHeight: '60vh',
            }}
          >
            {messages.length == 0 ? (
              <ListItem>{vocabulary.chatPlaceholder[langSelected]}</ListItem>
            ) : null}
            {messages.map((message, index) => (
              <ListItem key={index} sx={{ alignItems: 'flex-start', mb: 2 }}>
                <ListItemIcon sx={{ mt: 2 }}>
                  {message.role === 'user' ? <User /> : <Robot />}
                </ListItemIcon>
                <ListItemText
                  sx={{ whiteSpace: 'pre-line', ml: 1 }}
                  primary={
                    <Typography variant='body1' component='p'>
                      {message.content}
                    </Typography>
                  }
                  secondary={
                    <Typography variant='body2' color='textSecondary'>
                      {message.role === 'user'
                        ? state?.userInfo?.givenName
                        : message.role}
                    </Typography>
                  }
                />
              </ListItem>
            ))}

            {isLoading && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  my: 2,

                  left: 'calc(50% - 20px)',
                  transform: 'translateX(-50%)',
                }}
              />
            )}
          </List>
        </Box>
        <Box
          sx={{
            mx: 4,
            mt: 1,
          }}
        ></Box>

        <Box
          display='flex'
          sx={{
            m: 4,
          }}
        >
          <TextField
            fullWidth
            variant='outlined'
            disabled={isLoading}
            size='small'
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            placeholder={vocabulary.writeMessage[langSelected]}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleSendMessage()
              }
            }}
          />
          <Button
            disabled={isLoading}
            type='primary'
            onClick={handleSendMessage}
            sx={{ ml: 2 }}
          >
            {vocabulary.send[langSelected]}
          </Button>
        </Box>
      </Box>
    </DialogModalWindow>
  )
}
