import * as React from 'react'
import { CircularProgress } from '@mui/material/'
import GlobalContext from 'GlobalContext'
import { usePutUserRequest } from 'hooks/requests/usePutUserRequest'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'

export default function Payment() {
  const { GlobalActions, state } = React.useContext(GlobalContext)
  const [isLoading, setIsLoading] = React.useState(true);

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const onSendUserRequestSuccess = () => {
    setIsLoading(false);
    GlobalActions.setDialogServiceOpen({
      serviceIndex: state.dialogService?.serviceIndex,
      step: (state.dialogService?.step || 0) + 1,
    })
  }

  const { sendUserRequest } = usePutUserRequest({
    onSuccess: onSendUserRequestSuccess,
  })

  const handleConfirm = () => {
    sendUserRequest()
  }

  React.useEffect(() => {
    handleConfirm();
  }, [])

  return (
    <WorkflowContainer
      onClose={handleClose}
    >
      {isLoading ? <CircularProgress sx={
        {
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)',
        }
      } /> : null}
    </WorkflowContainer>
  )
}
