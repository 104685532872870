import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material/'
import GlobalContext from 'GlobalContext'
import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import { usePutUserRequest } from 'hooks/requests/usePutUserRequest'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'

export default function Payment({ price }) {
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)
  const [payNow, setPayNow] = React.useState('no')
  const [paymentMethod, setPaymentMethod] = React.useState('card')
  const [paymentInCourse, setPaymentInCourse] = React.useState(false)

  const priceToShow = state.dataToSend?.appointment
    ? price?.withAppointment
    : price?.withoutAppointment

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const onSendUserRequestSuccess = () => {
    const nextStepAdditionalValue = payNow === 'yes' ? 1 : 2

    GlobalActions.setDialogServiceOpen({
      serviceIndex: state.dialogService?.serviceIndex,
      step: (state.dialogService?.step || 0) + nextStepAdditionalValue,
    })
  }

  const { sendUserRequest } = usePutUserRequest({
    onSuccess: onSendUserRequestSuccess,
  })

  const handleConfirm = () => {
    setPaymentInCourse(true)
    sendUserRequest()
  }

  return (
    <WorkflowContainer
      onClose={handleClose}
      footer={
        <Box
          sx={{
            pb: 4,
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            mx: 2,
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ border: 'none', height: '40px', mb: 2 }}
            color='blueButton'
            disabled={paymentInCourse}
            onClick={handleConfirm}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {payNow !== 'yes'
                ? vocabularyWorkflow.sendRequest[langSelected]
                : vocabularyWorkflow.goToPayment[langSelected]}
            </Typography>
          </Button>
        </Box>
      }
    >
      <Typography component='p' variant='titleLarge' sx={{ mb: 4 }}>
        {vocabularyWorkflow.payment[langSelected]}
      </Typography>

      <Typography component='p' variant='bodySmall' sx={{ mb: 2 }}>
        {vocabularyWorkflow.paymentText[langSelected]}
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={payNow}
          onChange={event => setPayNow(event.target.value)}
          name='radio-buttons-group'
          sx={{ mb: 4 }}
        >
          <FormControlLabel
            sx={{ mb: 1 }}
            value='no'
            control={<Radio />}
            label={vocabularyWorkflow.payLater[langSelected]}
          />
          <FormControlLabel
            value='yes'
            control={<Radio />}
            label={vocabularyWorkflow.payNow[langSelected]}
          />
        </RadioGroup>
      </FormControl>

      {payNow === 'yes' && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              <Typography component='p' variant='bodySmall' sx={{ mb: 2 }}>
                {vocabularyWorkflow.selectedPrice[langSelected]}
              </Typography>
              <Typography
                component='p'
                variant='labelLarge'
                color='primary'
                sx={{ mb: 2, width: '50%' }}
              >
                {state.dataToSend?.appointment
                  ? vocabularyWorkflow.withAppointment[langSelected]
                  : vocabularyWorkflow.withoutAppointment[langSelected]}
              </Typography>
            </Box>
            <Box>
              <Typography
                component='p'
                variant='h2Large'
                color='primary'
                sx={{ mb: 2, width: '50%', mt: 5 }}
              >
                {priceToShow ? `${priceToShow}` : 'unknown'}$
              </Typography>
            </Box>
          </Box>

          <Typography component='p' variant='bodySmall' sx={{ mb: 2, mt: 4 }}>
            {vocabularyWorkflow.paymentVariant[langSelected]}
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              value={paymentMethod}
              onChange={event => setPaymentMethod(event.target.value)}
              name='radio-buttons-group'
            >
              <FormControlLabel
                sx={{ mb: 1 }}
                value='card'
                control={<Radio />}
                label={vocabularyWorkflow.card[langSelected]}
              />
            </RadioGroup>
          </FormControl>

          <Typography component='p' variant='bodySmall' sx={{ mt: 2 }}>
            {vocabularyWorkflow.paymentDetail[langSelected]}
          </Typography>
        </>
      )}
    </WorkflowContainer>
  )
}
