const vocabularyOther = {
  otherServices: [
    'Другие консульские услуги',
    'Other consular services',
    '其他领事服务',
    'Otros servicios consulares',
  ],
  legalization: [
    'Легализация документов',
    'Documents legalization',
    '文件公证',
    'Legalización de documentos',
  ],
  infoText1: [
    'Для того, что иностранный документ имел юридическую силу на территории России, необходимо пройти процедуру легализации. Это означает подтвердить подлинность подписи и печати выдавшего его учреждения.',
    'In order for a foreign document to have legal force on the territory of Russia, it is necessary to go through the legalization procedure. This means confirming the authenticity of the signature and seal of the issuing institution.',
    '为了使外国文件在俄罗斯境内具有法律效力，必须经过公证程序。这意味着确认签署和印章的真实性。',
    'Para que un documento extranjero tenga fuerza legal en el territorio de Rusia, es necesario pasar por el procedimiento de legalización. Esto significa confirmar la autenticidad de la firma y el sello de la institución emisora.',
  ],
  infoText2: [
    'Если документ представлен на иностранном языке, дополнительно необходимо сделать нотариально заверенный перевод на русский язык.',
    'If the document is in a foreign language, it is additionally necessary to make a notarized translation into Russian.',
    '如果文件是外语，还需要进行公证的俄语翻译。',
    'Si el documento está en un idioma extranjero, también es necesario hacer una traducción notariada al ruso.',
  ],
  infoText3: [
    'То, насколько сложной, дорогой и длительной по времени будет эта процедура, зависит от типа документа и страны его получения.',
    'How complex, expensive and time-consuming this procedure will be depends on the type of document and the country in which it was obtained.',
    '该程序的复杂程度、费用和所需时间取决于文件的类型和其获取国家。',
    'La complejidad, el costo y el tiempo que tomará este procedimiento dependen del tipo de documento y del país en el que se obtuvo.',
  ],
  infoText4: [
    'Мы проконсультируем вас, как необходимо поступить в вашем конкретном случае, сколько по времени может занять весь процесс, как подготовить документы для легализации и поможем заполнить заявление на консульском портале.',
    'We will advise you on what to do in your particular case, how long the whole process may take, how to prepare documents for legalization and will help you fill out an application on the consulate portal.',
    '我们会建议您在具体情况下应采取的措施，整个过程可能需要多长时间，如何准备公证文件，并帮助您在领事馆门户网站上填写申请。',
    'Le asesoraremos sobre qué hacer en su caso particular, cuánto tiempo puede tomar todo el proceso, cómo preparar los documentos para la legalización y le ayudaremos a completar una solicitud en el portal consular.',
  ],
  certificateReturn: [
    'Свидетельство на возвращение в Россию',
    'Certificate for return to Russia',
    '返回俄罗斯的证明',
    'Certificado de retorno a Rusia',
  ],
  infoText5: [
    'Если у вас нет действующего загранпаспорта, для срочного въезда на территорию России вы можете оформить свидетельство на возвращение в Россию.',
    'If you do not have a valid passport, for urgent entry into the territory of Russia you can obtain a certificate for return to Russia.',
    '如果您没有有效护照，您可以申请返回俄罗斯的证明，以便紧急进入俄罗斯境内。',
    'Si no tiene un pasaporte válido, puede obtener un certificado de retorno a Rusia para una entrada urgente al territorio de Rusia.',
  ],

  whoCanApply: [
    'Кто может обратиться за получением:',
    'Who can apply:',
    '申请条件:',
    '¿Quién puede solicitar:',
  ],
  certificateReturnList: [
    [
      'граждане РФ, у кого загранпаспорт был утерян, испорчен или истёк срок его действия',
      'citizens of the Russian Federation whose passport has been lost, damaged or has expired',
      '俄罗斯联邦公民，护照丢失、损坏或过期',
      'ciudadanos de la Federación Rusa cuyo pasaporte ha sido perdido, dañado o ha expirado',
    ],
    [
      'граждане РФ, проживающие за рубежом, которым не могут выдать загранпаспорт из-за ограничений на выезд из РФ (например, из-за доступа к государственной тайне, военной службы, наличия судимости и других причин)',
      'citizens of the Russian Federation living abroad who cannot be issued a foreign passport due to restrictions on leaving the Russian Federation (for example, due to access to state secrets, military service, criminal record and other reasons)',
      '俄罗斯联邦公民在国外生活，因限制离开俄罗斯联邦而无法获得护照（例如，由于接触国家机密、军事服役、刑事记录等原因）',
      'ciudadanos de la Federación Rusa que viven en el extranjero y a quienes no se les puede emitir un pasaporte extranjero debido a restricciones para salir de la Federación Rusa (por ejemplo, debido al acceso a secretos de estado, servicio militar, antecedentes penales y otras razones)',
    ],
    [
      'лица без гражданства, проживающие в РФ, либо беженцы, утратившие документ, подтверждающий их статус',
      'stateless persons living in the Russian Federation, or refugees who have lost a document confirming their status',
      '无国籍人士在俄罗斯联邦居住，或失去证明其身份的文件的难民',
      'personas apátridas que viven en la Federación Rusa o refugiados que han perdido un documento que confirma su estatus',
    ],
  ],
  infoText6: [
    'Если у гражданина России, находящегося за границей, никогда не было собственного загранпаспорта, он не может получить свидетельство на возвращение. В этом случае потребуется оформлять загранпаспорт.',
    'If a Russian citizen who is abroad has never had his own foreign passport, he/she cannot obtain a certificate for return. In this case, you will need to apply for a foreign passport.',
    '如果在国外的俄罗斯公民从未拥有过自己的护照，他/她无法获得返回证明。在这种情况下，您需要申请护照。',
    'Si un ciudadano ruso que se encuentra en el extranjero nunca ha tenido su propio pasaporte extranjero, no puede obtener un certificado de retorno. En este caso, deberá solicitar un pasaporte extranjero.',
  ],
  infoText7: [
    'Свидетельство на возвращение может быть, например, выдано на основании внутреннего паспорта РФ или письменного показания двух других граждан РФ, подтверждающих вашу личность. При отсутствии обоих действующий паспортов и свидетелей может потребоваться предварительно пройти процедуру проверки принадлежности к гражданству РФ.',
    'A certificate for return can, for example, be issued on an internal passport of the Russian Federation or an affidavit of two other citizens of the Russian Federation confirming your identity. If you do not have both valid passports and witnesses, you may need to first go through a procedure to verify your Russian citizenship.',
    '返回证明可以根据俄罗斯联邦的内部护照或两名其他俄罗斯公民的宣誓书签发，以确认您的身份。如果您没有有效护照和见证人，您可能需要首先经过确认您俄罗斯国籍的程序。',
    'Un certificado de retorno puede, por ejemplo, emitirse sobre la base de un pasaporte interno de la Federación Rusa o una declaración escrita de otros dos ciudadanos de la Federación Rusa que confirmen su identidad. Si no tiene pasaportes válidos y testigos, es posible que primero deba pasar por un procedimiento para verificar su ciudadanía rusa.',
  ],
  infoText8: [
    'Для подачи заявления предварительная запись в консульство не требуется.',
    'To submit an application, the prior registration at the consulate is not required.',
    '提交申请无需提前在领事馆登记。',
    'Para presentar una solicitud, no es necesario registrarse previamente en el consulado.',
  ],
  infoText9: [
    'Срок оформления — от 2 до 27 дней, срок действия документа — 14 дней.',
    'The processing time is from 2 to 27 days, the document is valid for 14 days.',
    '处理时间为2至27天，文件有效期为14天。',
    'El tiempo de procesamiento es de 2 a 27 días, el documento es válido por 14 días.',
  ],
  infoText10: [
    'Свидетельство на возвращение не заменяет собой загранпаспорт и предназначено исключительно для однократного въезда на территорию России.',
    'The certificate for return does not replace a foreign passport and is intended exclusively for a single entry into the territory of Russia.',
    '返回证明不能替代护照，仅用于一次性入境俄罗斯境内。',
    'El certificado de retorno no reemplaza un pasaporte extranjero y está destinado exclusivamente a una entrada única en el territorio de Rusia.',
  ],
  infoText11: [
    'В случае отсутствия прямых рейсов в РФ из страны консульства, рекомендуется заранее уточнить у авиакомпании, пустят ли вас с данным документом на рейс.',
    'If there are no direct flights to the Russian Federation from the country of the consulate, it is recommended to check with the airline in advance whether they will allow you on the flight with this document.',
    '如果从领事馆所在国没有直飞俄罗斯联邦的航班，建议提前向航空公司确认他们是否允许您携带该文件登机。',
    'Si no hay vuelos directos a la Federación Rusa desde el país del consulado, se recomienda consultar con la aerolínea con anticipación si le permitirán abordar el vuelo con este documento.',
  ],
  infoText12: [
    'В течение 3 дней после прибытия в Россию свидетельство необходимо сдать в территориальный орган ФМС по месту жительства.',
    'Within 3 days after arrival to Russia, the certificate must be submitted to the territorial body of the Federal Migration Service at your place of residence.',
    '在抵达俄罗斯后的3天内，必须将证书提交到您居住地的联邦移民局地方机关。',
    'Dentro de los 3 días posteriores a la llegada a Rusia, el certificado debe entregarse al organismo territorial del Servicio Federal de Migración en su lugar de residencia.',
  ],

  otherTypes: [
    {
      userName: [
        'Легализация документов',
        'Legalization of documents',
        '文件公证',
        'Legalización de documentos',
      ],

      desc: [
        ['заявление', 'Application', '申请', 'Solicitud'],
        [
          'внутренний или заграничный паспорт РФ',
          'internal or foreign passport of the Russian Federation',
          '俄罗斯联邦内部护照或外国护照',
          'pasaporte interno o extranjero de la Federación Rusa',
        ],
        [
          'документ, подлежащий легализации',
          'document subject to legalization',
          '需公证的文件',
          'documento sujeto a legalización',
        ],
      ],
    },
    {
      userName: [
        'Свидетельство на возвращение в Россию',
        'A certificate of return to Russia',
        '返回俄罗斯的证明',
        'Certificado de retorno a Rusia',
      ],

      desc: [
        ['заявление', 'Application', '申请', 'Solicitud'],
        [
          'внутренний паспорт РФ, свидетельство о рождении (для детей до 14 лет) или письменные заявления двух свидетелей (граждан России, имеющих действительные загранпаспорта)',
          'internal passport of the Russian Federation, birth certificate (for children under 14 years of age) or written statements of two witnesses (Russian citizens with valid international passports)',
          '俄罗斯联邦内部护照、出生证明（14岁以下儿童）或两名见证人（持有效护照的俄罗斯公民）书面声明',
          'pasaporte interno de la Federación Rusa, certificado de nacimiento (para niños menores de 14 años) o declaraciones escritas de dos testigos (ciudadanos rusos con pasaportes internacionales válidos)',
        ],
        [
          'просроченный или испорченный загранпаспорт (при наличии)',
          'expired or damaged passport (if available)',
          '过期或损坏的护照（如有）',
          'pasaporte caducado o dañado (si está disponible)',
        ],
        [
          'документы, подтверждающие родство или права законного представителя (для детей или недееспособных граждан)',
          'documents confirming kinship or the rights of a legal representative (for children or incapacitated citizens)',
          '证明亲属关系或法定代表人权利的文件（适用于儿童或无行为能力公民）',
          'documentos que confirmen el parentesco o los derechos de un representante legal (para niños o ciudadanos incapaces)',
        ],
        [
          '2 фотографии 3,5 × 4,5 см',
          '2 photo 3.5x4.5 cm',
          '2张3.5×4.5厘米的照片',
          '2 fotos de 3.5x4.5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de fotos',
      ],
    },
  ],
  priceText: [
    'Стоимость услуги зависит от страны обращения и ваших индивидуальных обстоятельств. Точную сумму можно узнать после консультации с юристом.',
    'The cost of the service depends on the country of application and your individual circumstances. The exact amount can be determined after consultation with a lawyer.',
    '服务费用取决于申请国和您的具体情况。确切的金额可以在与律师咨询后确定。',
    'El costo del servicio depende del país de solicitud y de sus circunstancias individuales. La cantidad exacta se puede determinar después de consultar a un abogado.',
  ],

  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for notarization',
        'We will schedule an appointment at the consulate for you',
      ],
      value: '60',
    },
    {
      header: '有预约的领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户上提交文件',
        '我们将咨询您所需的公证文件清单',
        '我们将为您安排领事馆的预约',
      ],
      value: '60',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Le asesoraremos sobre la lista de documentos necesarios para la notarización',
        'Le programaremos una cita en el consulado',
      ],
      value: '60',
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов,',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'Provide consultation on scheduling an appointment at the consulate independently',
      ],
      value: '80',
    },
    {
      header: '没有预约的领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户上提交文件',
        '提供关于如何独立预约领事馆的咨询',
      ],
      value: '80',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Proporcionaremos asesoría sobre cómo programar una cita en el consulado de forma independiente',
      ],
      value: '80',
    },
  ],

  faq: [
    {
      userName: [
        'Что такое апостиль и зачем он нужен?',
        'What is an apostille and why is it needed?',
        '什么是公证书，为什么需要它？',
        '¿Qué es un apostille y por qué se necesita?',
      ],
      desc: [
        [
          'Апостиль — это специальный штамп, который подтверждает подлинность официального документа для использования за границей.',
          'One of the child’s parents (legal representative) who has Russian citizenship.',
          '公证书是一种特殊的印章，确认官方文件的真实性，以便在国外使用。',
          'Un apostille es un sello especial que confirma la autenticidad de un documento oficial para su uso en el extranjero.',
        ],
        [
          'Россия подписала Гаагскую конвенцию 1961 года, которая упростила процесс признания документов в других странах. Вместо сложных процедур дипломатической или нотариальной легализации теперь достаточно проставить апостиль. Документы с апостилем признаются действительными во всех странах, подписавших эту конвенцию, без дополнительной проверки.',
          'Russia signed the 1961 Hague Convention, which simplified the process of document recognition in other countries. Instead of complex procedures for diplomatic or notarial legalization, it is now enough to affix an apostille. Documents with an apostille are recognized as valid in all countries that have signed this convention, without additional verification.',
          '俄罗斯签署了1961年的海牙公约，简化了在其他国家承认文件的过程。现在只需放置公证书，而无需复杂的外交或公证程序。带有公证书的文件在所有签署该公约的国家中被视为有效，无需额外验证。',
          'Rusia firmó la Convención de La Haya de 1961, que simplificó el proceso de reconocimiento de documentos en otros países. En lugar de procedimientos complejos de legalización diplomática o notarial, ahora es suficiente colocar un apostille. Los documentos con apostille son reconocidos como válidos en todos los países que firmaron esta convención sin verificación adicional.',
        ],
      ],
    },
    {
      userName: [
        'Где можно поставить апостиль?',
        'Where can I affix an apostille?',
        '我可以在哪里获得公证书？',
        '¿Dónde puedo obtener un apostille?',
      ],
      desc: [
        [
          'Апостиль проставляется непосредственно в ведомстве, выдавшем документ.',
          'The apostille is affixed directly at the institution that issued the document.',
          '公证书直接在发放文件的机构处签署。',
          'El apostille se coloca directamente en la institución que emitió el documento.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I affix an apostille to a registry office document at the consulate?',
        '我可以在领事馆为公民登记文件办理公证吗？',
        '¿Puedo obtener un apostille en un documento de registro civil en el consulado?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы.',
          'No, the consulate cannot certify the documents issued in the Russian Federation with an apostile.',
          '不，领事馆不能对在俄罗斯联邦发放的文件进行公证。',
          'No, el consulado no puede apostillar documentos emitidos en la Federación Rusa.',
        ],
        [
          'Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
          'However, through the consulate you can request a duplicate civil registry office document from Russia with an apostille already affixed to it.',
          '但是，通过领事馆，您可以请求从俄罗斯获取带有公证的公民登记文件副本。',
          'Sin embargo, a través del consulado, puede solicitar un duplicado del documento de registro civil de Rusia con un apostille ya colocado en él.',
        ],
      ],
    },
  ],

  faqDesc: [
    [
      {
        question: 'Что такое апостиль и зачем он нужен?',
        answer:
          'Апостиль — это специальный штамп, который подтверждает подлинность официального документа для использования за границей.',
        answer2:
          'Россия подписала Гаагскую конвенцию 1961 года, которая упростила процесс признания документов в других странах. Вместо сложных процедур дипломатической или нотариальной легализации теперь достаточно проставить апостиль. Документы с апостилем признаются действительными во всех странах, подписавших эту конвенцию, без дополнительной проверки.',
      },
      {
        question: 'Где можно поставить апостиль?',
        answer:
          'Апостиль проставляется непосредственно в ведомстве, выдавшем документ.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы.',
        answer2:
          'Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
      },
    ],
    [
      {
        question: 'What is an apostille and why is it needed?',
        answer:
          'An apostille is a special stamp that confirms the authenticity of an official document for use abroad.',
        answer2:
          'Russia signed the Hague Convention of 1961, which simplified the process of recognizing documents in other countries. Instead of complex procedures of diplomatic or notarial legalization, it is now enough to place an apostille. Documents with an apostille are recognized as valid in all countries that signed this convention without additional verification.',
      },
      {
        question: 'Where can I get an apostille?',
        answer:
          'An apostille is placed directly at the agency that issued the document.',
      },
      {
        question:
          'Can I get an apostille on a civil registry document at the consulate?',
        answer:
          'No, the consulate cannot apostille documents issued in the Russian Federation.',
        answer2:
          'However, through the consulate, you can request a duplicate civil registry document from Russia with an apostille already placed on it.',
      },
    ],
    [
      {
        question: '什么是公证书，为什么需要它？',
        answer:
          '公证书是一种特殊的印章，确认官方文件的真实性，以便在国外使用。',
        answer2:
          '俄罗斯签署了1961年的海牙公约，简化了在其他国家承认文件的过程。现在只需放置公证书，而无需复杂的外交或公证程序。带有公证书的文件在所有签署该公约的国家中被视为有效，无需额外验证。',
      },
      {
        question: '我可以在哪里获得公证书？',
        answer: '公证书直接在发放文件的机构处签署。',
      },
      {
        question: '我可以在领事馆为公民登记文件办理公证吗？',
        answer: '不，领事馆不能对在俄罗斯联邦发放的文件进行公证。',
        answer2:
          '但是，通过领事馆，您可以请求从俄罗斯获取带有公证的公民登记文件副本。',
      },
    ],
    [
      {
        question: '¿Qué es un apostille y por qué se necesita?',
        answer:
          'Un apostille es un sello especial que confirma la autenticidad de un documento oficial para su uso en el extranjero.',
        answer2:
          'Rusia firmó la Convención de La Haya de 1961, que simplificó el proceso de reconocimiento de documentos en otros países. En lugar de procedimientos complejos de legalización diplomática o notarial, ahora es suficiente colocar un apostille. Los documentos con apostille son reconocidos como válidos en todos los países que firmaron esta convención sin verificación adicional.',
      },
      {
        question: '¿Dónde puedo obtener un apostille?',
        answer:
          'Un apostille se coloca directamente en la agencia que emitió el documento.',
      },
      {
        question:
          '¿Puedo obtener un apostille en un documento de registro civil en el consulado?',
        answer:
          'No, el consulado no puede apostillar documentos emitidos en la Federación Rusa.',
        answer2:
          'Sin embargo, a través del consulado, puede solicitar un duplicado del documento de registro civil de Rusia con un apostille ya colocado en él.',
      },
    ],
  ],

  workflow: {
    requestServiceType: {
      title: [
        'Какая консульская услуга вам необходима?',
        'What consular service do you need?',
        '您需要什么领事服务？',
        '¿Qué servicio consular necesita?',
      ],
      options: [
        [
          'Свидетельство на возвращение в Россию',
          'Certificate for return to Russia',
          '返回俄罗斯的证明',
          'Certificado para regresar a Rusia',
        ],
        [
          'Легализация документов',
          'Legalization of documents',
          '文件合法化',
          'Legalización de documentos',
        ],
        ['Другая', 'Other', '其他', 'Otra'],
      ],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
        '用您自己的话描述需要什么',
        'Describa con sus propias palabras qué se requiere',
      ],
    },
    requestDocumentsDisponible: {
      title: [
        'Какие документы у вас есть в наличии?',
        'What documents do you have?',
        '您手头有什么文件？',
        '¿Qué documentos tiene?',
      ],
      additionalInfo: [
        'Например. внутренний паспорт, загранпаспорт с истекшим сроком действия, испорченный загранпаспорт, водительские права',
        "For example, internal passport, expired foreign passport, damaged foreign passport, driver's license",
        '例如：内部护照、过期护照、损坏护照、驾驶执照',
        'Por ejemplo, pasaporte interno, pasaporte extranjero expirado, pasaporte extranjero dañado, licencia de conducir',
      ],
    },
    requestDocumentToLegalize: {
      title: [
        'Какой документ нужно легализовать?',
        'What document needs to be legalized?',
        '需要合法化哪个文件？',
        '¿Qué documento necesita ser legalizado?',
      ],
      additionalInfo: [
        'Опишите своими словами, что за документ, где и когда был выдан',
        'Describe in your own words what the document is, where and when it was issued',
        '用您自己的话描述这个文件是什么，在哪里和何时颁发的',
        'Describa con sus propias palabras qué documento es, dónde y cuándo fue emitido',
      ],
    },
    requestDetails: {
      title: [
        'Опишите своими словами вашу проблему',
        'Describe your problem in your own words',
        '用您自己的话描述您的问题',
        'Describa su problema con sus propias palabras',
      ],
      additionalInfo: [
        'Опишите вашу ситуацию',
        'Describe your situation',
        '描述您的情况',
        'Describa su situación',
      ],
    },
  },
}

export default vocabularyOther
