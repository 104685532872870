import { Buildings, Stamp, MedalMilitary, Gavel } from '@phosphor-icons/react'
import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import marriageRingsImage from '../images/marriage-black.png'
export const serviceTypes = [
  [
    'Консульскую справку',
    'Consular certificate',
    '领事证明',
    'Certificado consular',
  ],
  [
    'Истребовать документ из России',
    'Request a document from Russia',
    '请求从俄罗斯获取文件',
    'Solicitar un documento de Rusia',
  ],
]

export const certificateTypes = [
  [
    'Справка о несудимости',
    'Criminal record certificate',
    '无犯罪证明',
    'Certificado de antecedentes penales',
  ],
  [
    'Заявление о брачной правоспособности',
    'Marriage capacity certificate',
    '婚姻能力证明',
    'Certificado de capacidad matrimonial',
  ],
  [
    'Справка о ПМЖ за пределами РФ',
    'Permanent residence certificate outside the Russian Federation',
    '俄罗斯联邦境外的永久居留证明',
    'Certificado de residencia permanente fuera de la Federación Rusa',
  ],
  [
    'Акт о личной явке',
    'Personal attendance act',
    '亲自到场证明',
    'Acta de presencia personal',
  ],
  [
    'Справка о работе за пределами РФ',
    'Work certificate outside the Russian Federation',
    '俄罗斯联邦境外工作证明',
    'Certificado de trabajo fuera de la Federación Rusa',
  ],
]

export const documentTypes = [
  [
    'Документ ЗАГС',
    'Civil registry document',
    '民事登记文件',
    'Documento del registro civil',
  ],
  [
    'Справка о семейном положении',
    'Certificate of marital status',
    '婚姻状况证明',
    'Certificado de estado civil',
  ],
  [
    'Справка о стаже и трудовой деятельности',
    'Certificate of work experience and employment',
    '工作经历和就业证明',
    'Certificado de experiencia laboral y empleo',
  ],
  [
    'Справка о прохождении воинской службы',
    'Certificate of military service',
    '兵役证明',
    'Certificado de servicio militar',
  ],
]

const vocabularyCertificates = {
  certificates: ['Справки', 'Certificates', '证明', 'Certificados'],
  certificatesText1: [
    'Для получения консульских справок требуется представить заявление, которое в своё очередь будет должным образом заверено консульским работником.',
    'To obtain consular certificates, it is necessary to submit an application, that will be properly certified by consular staff.',
    '要获得领事证明，必须提交申请，该申请将由领事工作人员适当认证。',
    'Para obtener certificados consulares, es necesario presentar una solicitud que será debidamente certificada por el personal consular.',
  ],
  certificatesText2: [
    'Мы поможем вам составить заявление в требуемом формате, проконсультируем по списку документов и записи на приём в консульство или запишем вас сами, и разместим готовый проект документа на консульском портале.',
    'We will assist you in drafting the application in the required format, provide consultation on the list of documents and scheduling an appointment at the consulate, or schedule an appointment for you, and submit the prepared draft document on the consulate portal.',
    '我们将帮助您以所需格式草拟申请，提供文件清单和预约领事馆的咨询，或者为您预约，并在领事馆门户上提交准备好的文档草稿。',
    'Le ayudaremos a redactar la solicitud en el formato requerido, le asesoraremos sobre la lista de documentos y la programación de una cita en el consulado, o programaremos una cita por usted y enviaremos el borrador del documento preparado en el portal consular.',
  ],
  typesOfCertificates: [
    {
      section: [
        'Консульские справки:',
        'Consular certificates',
        '领事证明：',
        'Certificados consulares',
      ],
      types: [
        {
          id: '0',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[0][1],
          text: [
            'На Справка о несудимости',
            'Certificate of No Criminal Record',
            '无犯罪证明',
            'Certificado de antecedentes penales',
          ],
          icon: <Gavel size={24} weight='light' />,
        },
        {
          id: '1',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[1][1],
          text: [
            'Заявление о брачной правоспособности',
            'Marriage Eligibility Statement',
            '婚姻能力声明',
            'Declaración de elegibilidad matrimonial',
          ],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '2',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[2][1],
          text: [
            'Справка о ПМЖ за пределами РФ',
            'Certificate of Permanent Residency Abroad (outside the Russian Federation)',
            '俄罗斯联邦境外的永久居留证明',
            'Certificado de residencia permanente en el extranjero (fuera de la Federación Rusa)',
          ],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: '3',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[3][1],
          text: [
            'Акт о личной явке',
            'Personal Appearance Report',
            '个人出席报告',
            'Informe de asistencia personal',
          ],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: '4',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[4][1],
          text: [
            'Справка о работе за рубежом',
            'Verification of Employment Abroad',
            '海外工作验证',
            'Verificación de empleo en el extranjero',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      id: 'documents-retrieval',
      section: [
        'Истребование документов',
        'Document retrieval',
        '文件请求',
        'Solicitud de documentos',
      ],
      types: [
        {
          id: '5',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[0][1],
          text: [
            'Документы ЗАГС',
            'Civil Registry Office (ZAGS) documents',
            '民事登记办公室（ZAGS）文件',
            'Documentos de la oficina del registro civil (ZAGS)',
          ],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '6',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[1][1],
          text: [
            'Справка о семейном положении',
            'Certificate of marital status',
            '婚姻状况证明',
            'Certificado de estado civil',
          ],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '7',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[2][1],
          text: [
            'Справка о стаже и трудовой деятельности',
            'Certificate of employment history and work experience',
            '工作经历和就业证明',
            'Certificado de experiencia laboral y historial laboral',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: '8',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[3][1],
          text: [
            'Справка о прохождении воинской службы',
            'Military service record',
            '兵役记录',
            'Registro de servicio militar',
          ],
          icon: <MedalMilitary size={24} weight='light' />,
        },
      ],
    },
  ],
  certificatesText3: [
    'Срок истребования документов может составлять до 6 месяцев.',
    'The document retrieval process may take up to 6 months.',
    '文件请求过程可能需要长达6个月的时间。',
    'El proceso de solicitud de documentos puede tardar hasta 6 meses.',
  ],
  certificatesText4: [
    'Дополнительно можно запросить проставить на запрошенный документ апостиль.',
    'Additionally, you can request that an apostille be affixed to the requested document.',
    '此外，您可以请求在所请求的文件上加盖公证书。',
    'Además, puede solicitar que se le coloque un apostille en el documento solicitado.',
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформите заявку',
        cardText:
          'Опишите, для каких целей требуется справка. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста заявления',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект заявления и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для получения консульской услуги',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submit an Application',
        cardText:
          'Describe for what purposes the certificate is required. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of the Application Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft application, and post it on the MFA Russia portal. You will receive a placement number that you need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with your passport and accommodation number to receive consular service.',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '提交申请',
        cardText:
          '描述申请证书的目的。您可以在申请时同时向我们发送所需文件，也可以在咨询律师后直接发送给律师。',
        cardImage: checkBoxImage,
      },
      {
        headerText: '申请文本的准备',
        cardText:
          '我们的律师将与您联系，讨论细节，准备申请草稿，并将其发布在俄罗斯外交部门户网站上。您将收到一个放置编号，您需要将其带到领事馆。服务完成时间为付款之日起2个工作日。',
        cardImage: documentBrown,
      },
      {
        headerText: '在领事馆预约',
        cardText:
          '您可以自己预约领事馆，也可以在我们协助下进行预约（根据所选的收费标准）。',
        cardImage: calendarImage,
      },
      {
        headerText: '领取已准备好的文件',
        cardText: '在登记当天，请携带护照和住宿号码前往领事馆以接收领事服务。',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentar una Solicitud',
        cardText:
          'Describa para qué fines se requiere el certificado. Puede enviarnos los documentos requeridos simultáneamente con la solicitud o directamente al abogado después de consultarlo.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparación del Texto de la Solicitud',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles, preparará un borrador de la solicitud y lo publicará en el portal del Ministerio de Relaciones Exteriores de Rusia. Recibirá un número de referencia que debe llevar consigo al consulado. El tiempo de finalización del servicio es de 2 días hábiles a partir de la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programación de Citas en el Consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra asistencia (de acuerdo con la tarifa seleccionada).',
        cardImage: calendarImage,
      },
      {
        headerText: 'Recepción del Documento Listo',
        cardText:
          'El día de la inscripción, venga al consulado con su pasaporte y el número de referencia para recibir el servicio consular.',
        cardImage: stampImage,
      },
    ],
  ],
  list1: [
    [
      'внутренний паспорт и/или загранпаспорт РФ',
      'Internal passport and/or Russian international passport.',
      '护照和/或俄罗斯国际护照',
      'Pasaporte interno y/o pasaporte internacional ruso',
    ],
    [
      'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
      'Address of residence abroad in Russian language (if there is no registration in Russia).',
      '居住地址在国外的俄语（如果在俄罗斯没有注册）',
      'Dirección de residencia en el extranjero en ruso (si no hay registro en Rusia).',
    ],
    [
      'документы, подтверждающие родство (если запрашиваются документы на детей или умерших родственников) или представительство (если документы запрашиваются третьим лицом по нотариально удостоверенной доверенности)',
      'Documents confirming kinship (if documents for children or deceased relatives are requested) or representation (if documents are requested by a third party based on a notarized Power of Attorney).',
      '证明亲属关系的文件（如果请求儿童或已故亲属的文件）或代表权（如果文件由第三方根据公证的授权书请求）。',
      'Documentos que confirman el parentesco (si se solicitan documentos para niños o parientes fallecidos) o representación (si los documentos son solicitados por un tercero con base en un poder notarial).',
    ],
  ],

  certificatesText5: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the processing of notarial documents, priority is given to the internal passport. In case the internal passport has not been obtained or has expired, a valid international passport will be required. If both passports are available, it is necessary to bring both passports to the consulate appointment.',
    '为公证文件的处理，优先考虑内部护照。如果内部护照尚未获取或已过期，则需要有效的国际护照。如果同时有两个护照，则必须在领事馆预约时携带这两个护照。',
    'Para el procesamiento de documentos notariales, se da prioridad al pasaporte interno. En caso de que no se haya obtenido el pasaporte interno o haya expirado, se requerirá un pasaporte internacional válido. Si ambos pasaportes están disponibles, es necesario llevar ambos pasaportes a la cita en el consulado.',
  ],

  certificatesText6: [
    'Для справки о составе семьи дополнительно потребуются:',
    'For a family composition certificate, the following additional documents will be required:',
    '有关家庭组成证明，还需要以下额外文件：',
    'Para un certificado de composición familiar, se requerirán los siguientes documentos adicionales:',
  ],

  list2: [
    [
      'свидетельство о браке (если состояли в браке) и его расторжении (или смерти супруга)',
      'Marriage certificate (if married) and its dissolution (or death of the spouse).',
      '结婚证（如果结过婚）及其解除（或配偶去世）。',
      'Certificado de matrimonio (si está casado) y su disolución (o muerte del cónyuge).',
    ],
    [
      'свидетельства о рождении детей',
      'Birth certificates of children.',
      '儿童出生证明。',
      'Certificados de nacimiento de los niños.',
    ],
  ],

  certificatesText7: [
    'Для справок по вопросам пенсионных выплат дополнительно могут потребоваться:',
    'For inquiries regarding pension payments, the following additional documents may be required:',
    '有关养老金支付的查询，可能需要以下额外文件：',
    'Para consultas sobre pagos de pensiones, se pueden requerir los siguientes documentos adicionales:',
  ],

  list3: [
    [
      'прописка по месту проживания',
      'Registration at the place of residence.',
      '居住地登记。',
      'Registro en el lugar de residencia.',
    ],
    [
      'справка с места работы в стране проживания (если имеется)',
      'Certificate from the place of employment in the country of residence (if available).',
      '来自居住国工作的证明（如果有的话）。',
      'Certificado del lugar de empleo en el país de residencia (si está disponible).',
    ],
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'подготовим проект заявления',
        'разместим документ на консульском портале (если этого требует процедура)',
        'проконсультируем по перечню документов, необходимых для получения справки',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'Without Appointment at the Consulate',
      list: [
        'Prepare a draft application',
        'Submit the document on the consulate portal (if required by the procedure)',
        'Consult on the list of documents required for obtaining a certificate',
        'Consult on independently scheduling an appointment at the consulate',
      ],
      value: '40',
    },
    {
      header: '无预约到领事馆',
      list: [
        '准备申请草稿',
        '在领事馆门户网站上提交文件（如果程序要求）',
        '咨询获取证明所需的文件清单',
        '咨询如何独立预约领事馆的会见',
      ],
      value: '40',
    },
    {
      header: 'Sin Cita en el Consulado',
      list: [
        'Preparar un borrador de solicitud',
        'Enviar el documento en el portal consular (si lo requiere el procedimiento)',
        'Consultar sobre la lista de documentos necesarios para obtener un certificado',
        'Consultar sobre la programación independiente de una cita en el consulado',
      ],
      value: '40',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'подготовим проект заявления',
        'разместим документ на консульском портале (если этого требует процедура)',
        'проконсультируем по перечню документов, необходимых для получения справки',
        'запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With Appointment at the Consulate',
      list: [
        'Prepare a draft application',
        'Submit the document on the consulate portal (if required by the procedure)',
        'Consult on the list of documents required for obtaining a certificate',
        'Schedule an appointment for you at the consulate',
      ],
      value: '60',
    },
    {
      header: '预约到领事馆',
      list: [
        '准备申请草稿',
        '在领事馆门户网站上提交文件（如果程序要求）',
        '咨询获取证明所需的文件清单',
        '为您安排领事馆的会见',
      ],
      value: '60',
    },
    {
      header: 'Con Cita en el Consulado',
      list: [
        'Preparar un borrador de solicitud',
        'Enviar el documento en el portal consular (si lo requiere el procedimiento)',
        'Consultar sobre la lista de documentos necesarios para obtener un certificado',
        'Programar una cita para usted en el consulado',
      ],
      value: '60',
    },
  ],

  faq: [
    {
      userName: [
        'Как я могу получить справку об отсутствии судимости?',
        'How can I obtain a certificate of no criminal record?',
        '如何获得无犯罪记录证明？',
        '¿Cómo puedo obtener un certificado de antecedentes penales?',
      ],
      desc: [
        [
          'Первый способ: через консульство или посольство России. Срок оформления 2–4 недели.',
          'The first method: through the consulate or embassy of Russia. Processing time is 2–4 weeks.',
          '第一种方法：通过俄罗斯的领事馆或大使馆。处理时间为2–4周。',
          'Primer método: a través del consulado o embajada de Rusia. El tiempo de procesamiento es de 2 a 4 semanas.',
        ],
        [
          'Второй способ: через МВД по месту регистрации в России лично или по доверенности. Срок оформления — 1 месяц. Плюс дополнительно обычно на такой документ, оформленный в России, потребуется проставить «апостиль».',
          "The second method: through the Ministry of Internal Affairs (MVD) at the place of registration in Russia, either in person or through a Power of Attorney. Processing time is 1 month. Additionally, usually an 'apostille' will be required for such a document issued in Russia.",
          '第二种方法：通过俄罗斯内部事务部（MVD）在注册地点，亲自或通过授权书申请证明。处理时间为1个月。此外，通常会要求对在俄罗斯签发的此类文件加注“公证”。',
          'Segundo método: a través del Ministerio del Interior (MVD) en el lugar de registro en Rusia, ya sea en persona o mediante un poder notarial. El tiempo de procesamiento es de 1 mes. Además, generalmente se requerirá un "apostilla" para dicho documento emitido en Rusia.',
        ],
      ],
    },
    {
      userName: [
        'Для заключения брака с иностранцем требуют предоставить справку о том, что я не состою в другом браке. Как её получить?',
        'To marry a foreigner, you need to provide a certificate stating that you are not currently married. How can you obtain it?',
        '要与外国人结婚，您需要提供一份证明您当前未婚的证明。如何获得？',
        'Para casarse con un extranjero, necesitas proporcionar un certificado que indique que no estás casado actualmente. ¿Cómo puedes obtenerlo?',
      ],
      desc: [
        [
          'Первый способ: по доверенности в России или через консульство (процесс занимает 2–6 месяцев) истребовать в ЗАГС справку о том, что вы на данный момент не состоите в браке. На основании этой справки получить нужный документ в консульстве.',
          'First method: Through a Power of Attorney in Russia or through the consulate (the process takes 2–6 months) request a certificate from the Civil Registry Office (ZAGS) stating that you are not currently married. Based on this certificate, obtain the required document at the consulate.',
          '第一种方法：通过俄罗斯的授权书或通过领事馆申请证明，申请民事登记处（ZAGS）出具证明您当前未婚的证明（该过程需要2-6个月）。根据该证明，您可以在领事馆获得所需文件。',
          'Primer método: A través de un poder notarial en Rusia o a través del consulado (el proceso toma de 2 a 6 meses) solicitar un certificado de la Oficina del Registro Civil (ZAGS) que indique que actualmente no está casado. Con base en este certificado, obtenga el documento requerido en el consulado.',
        ],
        [
          'Второй способ: заверить в консульстве заявление о семейном положении (оно же: о составе семьи, о брачной правоспособности), где вы под личную ответственность указываете сведения о ваших прошлых браках и несовершеннолетних детях и подтверждаете, что не имеете препятствий для заключения брака в данный момент. Документ, оформленный вторым способом принимается не во всех странах.',
          'Second method: Certify a statement about your marital status (also about family composition, marriage eligibility) at the consulate. In this statement, under personal responsibility, you provide information about your past marriages and underage children and confirm that you have no impediments to marry at the moment. The document issued by the second method is not accepted in all countries.',
          '第二种方法：在领事馆对您的婚姻状况（也就是家庭组成、婚姻资格）进行声明认证。在这份声明中，您在个人责任下提供有关您过去婚姻和未成年子女的信息，并确认您目前没有结婚的障碍。第二种方式发出的文件并不是在所有国家都被接受。',
          'Segundo método: Certificar una declaración sobre su estado civil (también sobre la composición familiar, elegibilidad para el matrimonio) en el consulado. En esta declaración, bajo responsabilidad personal, proporciona información sobre sus matrimonios anteriores y hijos menores de edad y confirma que no tiene impedimentos para casarse en este momento. El documento emitido por el segundo método no es aceptado en todos los países.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли через консульство истребовать дубликат диплома ВУЗа?',
        'Is it possible to request a duplicate of a university diploma through the consulate?',
        '可以通过领事馆申请大学文凭的副本吗？',
        '¿Es posible solicitar un duplicado de un diploma universitario a través del consulado?',
      ],
      desc: [
        [
          'Нет, по истребованию дубликатов документов об образовании необходимо обращаться непосредственно в адрес учебного заведения или ВУЗа.',
          'No, for requesting duplicates of education documents, you need to contact the educational institution or university directly.',
          '不可以，申请教育文件的副本，您需要直接联系教育机构或大学。',
          'No, para solicitar duplicados de documentos educativos, debe comunicarse directamente con la institución educativa o la universidad.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I obtain an apostille for a document from the Civil Registry Office (ZAGS) at the consulate?',
        '我可以在领事馆为民事登记处（ZAGS）的文件申请公证吗？',
        '¿Puedo obtener una apostilla para un documento de la Oficina del Registro Civil (ZAGS) en el consulado?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем',
          'No, the consulate cannot apostille documents issued in Russia. However, through the consulate, you can request a duplicate document from the Civil Registry Office (ZAGS) in Russia with an apostille already affixed to it.',
          '不可以，领事馆不能对在俄罗斯签发的文件进行公证。然而，您可以通过领事馆申请从俄罗斯的民事登记处（ZAGS）申请已加注公证的副本文件。',
          'No, el consulado no puede apostillar documentos emitidos en Rusia. Sin embargo, a través del consulado, puede solicitar un duplicado de un documento de la Oficina del Registro Civil (ZAGS) en Rusia con la apostilla ya adjunta.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question: 'Как я могу получить справку об отсутствии судимости?',
        answer:
          'Первый способ: через консульство или посольство России. Срок оформления 2–4 недели.',
        answer2:
          'Второй способ: через МВД по месту регистрации в России лично или по доверенности. Срок оформления — 1 месяц. Плюс дополнительно обычно на такой документ, оформленный в России, потребуется проставить «апостиль».',
      },
      {
        question:
          'Для заключения брака с иностранцем требуют предоставить справку о том, что я не состою в другом браке. Как её получить?',
        answer:
          'Первый способ: по доверенности в России или через консульство (процесс занимает 2–6 месяцев) истребовать в ЗАГС справку о том, что вы на данный момент не состоите в браке. На основании этой справки получить нужный документ в консульстве.',
        answer2:
          'Второй способ: заверить в консульстве заявление о семейном положении (оно же: о составе семьи, о брачной правоспособности), где вы под личную ответственность указываете сведения о ваших прошлых браках и несовершеннолетних детях и подтверждаете, что не имеете препятствий для заключения брака в данный момент. Документ, оформленный вторым способом принимается не во всех странах.',
      },
      {
        question:
          'Можно ли через консульство истребовать дубликат диплома ВУЗа?',
        answer:
          'Нет, по истребованию дубликатов документов об образовании необходимо обращаться непосредственно в адрес учебного заведения или ВУЗа.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем',
      },
    ],

    [
      {
        question: 'How can I obtain a certificate of no criminal record?',
        answer:
          'The first method: through the consulate or embassy of Russia. Processing time is 2–4 weeks.',
        answer2:
          "The second method: through the Ministry of Internal Affairs (MVD) at the place of registration in Russia, either in person or through a Power of Attorney. Processing time is 1 month. Additionally, usually an 'apostille' will be required for such a document issued in Russia.",
      },
      {
        question:
          'To marry a foreigner, you need to provide a certificate stating that you are not currently married. How can you obtain it?',
        answer:
          'First method: Through a Power of Attorney in Russia or through the consulate (the process takes 2–6 months) request a certificate from the Civil Registry Office (ZAGS) stating that you are not currently married. Based on this certificate, obtain the required document at the consulate.',
        answer2:
          'Second method: Certify a statement about your marital status (also about family composition, marriage eligibility) at the consulate. In this statement, under personal responsibility, you provide information about your past marriages and underage children and confirm that you have no impediments to marry at the moment. The document issued by the second method is not accepted in all countries.',
      },
      {
        question:
          'Is it possible to request a duplicate of a university diploma through the consulate?',
        answer:
          'No, for requesting duplicates of education documents, you need to contact the educational institution or university directly.',
      },
      {
        question:
          'Can I obtain an apostille for a document from the Civil Registry Office (ZAGS) at the consulate?',
        answer:
          'No, the consulate cannot apostille documents issued in Russia. However, through the consulate, you can request a duplicate document from the Civil Registry Office (ZAGS) in Russia with an apostille already affixed to it.',
      },
    ],

    // Translations in Chinese and Spanish
    [
      {
        question: '如何获得无犯罪记录证明？',
        answer: '第一种方法：通过俄罗斯的领事馆或大使馆。处理时间为2–4周。',
        answer2:
          '第二种方法：通过俄罗斯内部事务部（MVD）在注册地点，亲自或通过授权书申请证明。处理时间为1个月。此外，通常会要求对在俄罗斯签发的此类文件加注“公证”。',
      },
      {
        question:
          '要与外国人结婚，您需要提供一份证明您当前未婚的证明。如何获得？',
        answer:
          '第一种方法：通过俄罗斯的授权书或通过领事馆申请证明，申请民事登记处（ZAGS）出具证明您当前未婚的证明（该过程需要2-6个月）。根据该证明，您可以在领事馆获得所需文件。',
        answer2:
          '第二种方法：在领事馆对您的婚姻状况（也就是家庭组成、婚姻资格）进行声明认证。在这份声明中，您在个人责任下提供有关您过去婚姻和未成年子女的信息，并确认您目前没有结婚的障碍。第二种方式发出的文件并不是在所有国家都被接受。',
      },
      {
        question: '可以通过领事馆申请大学文凭的副本吗？',
        answer: '不可以，申请教育文件的副本，您需要直接联系教育机构或大学。',
      },
      {
        question: '我可以在领事馆为民事登记处（ZAGS）的文件申请公证吗？',
        answer:
          '不可以，领事馆不能对在俄罗斯签发的文件进行公证。然而，您可以通过领事馆申请从俄罗斯的民事登记处（ZAGS）申请已加注公证的副本文件。',
      },
    ],

    [
      {
        question: '¿Cómo puedo obtener un certificado de antecedentes penales?',
        answer:
          'Primer método: a través del consulado o embajada de Rusia. El tiempo de procesamiento es de 2 a 4 semanas.',
        answer2:
          'Segundo método: a través del Ministerio del Interior (MVD) en el lugar de registro en Rusia, ya sea en persona o mediante un poder notarial. El tiempo de procesamiento es de 1 mes. Además, generalmente se requerirá un "apostilla" para dicho documento emitido en Rusia.',
      },
      {
        question:
          'Para casarse con un extranjero, necesitas proporcionar un certificado que indique que no estás casado actualmente. ¿Cómo puedes obtenerlo?',
        answer:
          'Primer método: A través de un poder notarial en Rusia o a través del consulado (el proceso toma de 2 a 6 meses) solicitar un certificado de la Oficina del Registro Civil (ZAGS) que indique que actualmente no está casado. Con base en este certificado, obtenga el documento requerido en el consulado.',
        answer2:
          'Segundo método: Certificar una declaración sobre su estado civil (también sobre la composición familiar, elegibilidad para el matrimonio) en el consulado. En esta declaración, bajo responsabilidad personal, proporciona información sobre sus matrimonios anteriores y hijos menores de edad y confirma que no tiene impedimentos para casarse en este momento. El documento emitido por el segundo método no es aceptado en todos los países.',
      },
      {
        question:
          '¿Es posible solicitar un duplicado de un diploma universitario a través del consulado?',
        answer:
          'No, para solicitar duplicados de documentos educativos, debe comunicarse directamente con la institución educativa o la universidad.',
      },
      {
        question:
          '¿Puedo obtener una apostilla para un documento de la Oficina del Registro Civil (ZAGS) en el consulado?',
        answer:
          'No, el consulado no puede apostillar documentos emitidos en Rusia. Sin embargo, a través del consulado, puede solicitar un duplicado de un documento de la Oficina del Registro Civil (ZAGS) en Rusia con la apostilla ya adjunta.',
      },
    ],
  ],

  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Справка о несудимости (об отсутствии или наличии судимости)',
          'Certificate of no criminal record (on the absence or presence of a criminal record).',
          '无犯罪记录证明（关于无犯罪或有犯罪记录）。',
          'Certificado de no antecedentes penales (sobre la ausencia o presencia de antecedentes penales).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Данный документ подтверждает, что человек был или не был судим на территории России.',
          'This document confirms whether a person has or has not been convicted in Russia.',
          '该文件确认一个人在俄罗斯是否被定罪。',
          'Este documento confirma si una persona ha sido o no condenada en Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Получить справку может любой гражданин РФ или иностранец старше 14 лет.',
          'Any citizen of the Russian Federation or a foreigner over 14 years old can obtain a certificate.',
          '任何俄罗斯联邦公民或14岁以上的外国人都可以获得此证明。',
          'Cualquier ciudadano de la Federación Rusa o un extranjero mayor de 14 años puede obtener un certificado.',
        ],
      },
      {
        type: 'list',
        title: [
          'В каких ситуациях требуется:',
          'In which situations is it required:',
          '在什么情况下需要：',
          'En qué situaciones se requiere:',
        ],
        items: [
          [
            'Оформление виз и ВНЖ',
            'Obtaining visas and residence permits',
            '签证和居留许可的办理',
            'Obtención de visas y permisos de residencia',
          ],
          ['Трудоустройство', 'Employment', '就业', 'Empleo'],
          [
            'Получение лицензий и разрешений',
            'Obtaining licenses and permits',
            '获得许可证和许可',
            'Obtención de licencias y permisos',
          ],
          [
            'Усыновление или опека',
            'Adoption or guardianship',
            '收养或监护',
            'Adopción o tutela',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок оформления через консульство — от 2 до 4 недель.',
          'Processing time through the consulate is 2 to 4 weeks.',
          '通过领事馆办理的时间为2到4周。',
          'El tiempo de procesamiento a través del consulado es de 2 a 4 semanas.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок её действия, как правило, не превышает 90 дней.',
          'Its validity period usually does not exceed 90 days.',
          '其有效期通常不超过90天。',
          'Su período de validez generalmente no excede los 90 días.',
        ],
      },
      {
        type: 'list',
        title: [
          'Документы для оформления:',
          'Documents required for application:',
          '申请所需文件：',
          'Documentos requeridos para la solicitud:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
            '内部护照和/或俄罗斯联邦护照（内部护照优先）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'последний адрес регистрации в России',
            'last registration address in Russia',
            '在俄罗斯的最后注册地址',
            'última dirección de registro en Rusia',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление о брачной правоспособности (о составе семьи, о семейном положении или статусе)',
          'Statement of marital capacity (about the family composition, marital status or status).',
          '结婚能力声明（关于家庭组成、婚姻状况或状态）。',
          'Declaración de capacidad matrimonial (sobre la composición familiar, estado civil o estatus).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для регистрации брака за границей необходимо предоставить документ, подтверждающий отсутствие препятствий к этому. Таким документом может быть выписка из реестра ЗАГС о том, что на данный момент гражданин не состоит в браке в РФ, однако истребовать такую справку через консульство может занять от 2 до 6 месяцев.',
          'To register a marriage abroad, it is necessary to provide a document confirming the absence of obstacles to this. Such a document may be an extract from the civil registry office stating that the citizen is not currently married in the Russian Federation, but obtaining such a certificate through the consulate may take from 2 to 6 months.',
          '要在国外注册结婚，必须提供一份确认没有障碍的文件。这样的文件可能是民事登记处的摘录，说明公民在俄罗斯联邦目前未婚，但通过领事馆获取这样的证明可能需要2到6个月。',
          'Para registrar un matrimonio en el extranjero, es necesario proporcionar un documento que confirme la ausencia de obstáculos para ello. Dicho documento puede ser un extracto de la oficina del registro civil que indique que el ciudadano no está casado actualmente en la Federación Rusa, pero obtener un certificado de este tipo a través del consulado puede tardar de 2 a 6 meses.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Как альтернативу, некоторые страны принимают в качестве документа о брачной правоспособности заверенное в консульстве заявление, в котором гражданин под свою ответственность расписывается в том, что препятствий к браку не имеет и указывает другие сведения о своём семейном положении.',
          'As an alternative, some countries accept a notarized statement in the consulate as a document of marital capacity, in which the citizen, under their own responsibility, declares that there are no obstacles to the marriage and provides other information about their marital status.',
          '作为替代，一些国家接受领事馆公证的声明作为结婚能力的文件，在该声明中，公民在其责任下声明没有结婚障碍，并提供其他关于其婚姻状况的信息。',
          'Como alternativa, algunos países aceptan una declaración notariada en el consulado como documento de capacidad matrimonial, en la cual el ciudadano, bajo su propia responsabilidad, declara que no hay obstáculos para el matrimonio y proporciona otra información sobre su estado civil.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления такого заявления потребуются:',
          'To issue such a statement, the following documents are required:',
          '要签发此类声明，需要以下文件：',
          'Para emitir tal declaración, se requieren los siguientes documentos:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
            '内部护照和/或俄罗斯联邦护照（内部护照优先）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'свидетельство о браке (если состояли в браке) и его расторжении (или смерти супруга)',
            'marriage certificate (if married) and its dissolution (or death of the spouse)',
            '结婚证（如果结过婚）及其解除（或配偶去世）',
            'certificado de matrimonio (si estuvo casado) y su disolución (o muerte del cónyuge)',
          ],
          [
            'свидетельства о рождении детей, если на момент оформления заявления они не достигли 18 лет',
            'birth certificates of children, if at the time of the application they are under 18 years old',
            '未成年子女的出生证明，如果在申请时他们未满18岁',
            'certificados de nacimiento de los hijos, si en el momento de la solicitud son menores de 18 años',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о подтверждении ПМЖ за пределами России',
          'Certificate of confirmation of permanent residence outside Russia.',
          '永久居留证明',
          'Certificado de confirmación de residencia permanente fuera de Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Одна из справок, которая может потребоваться для оформления пенсионных выплат гражданам, проживающим за рубежом.',
          'One of the certificates that may be required for processing pension payments to citizens living abroad.',
          '这是在国外居住的公民办理养老金支付时可能需要的证明之一。',
          'Uno de los certificados que puede ser necesario para procesar los pagos de pensiones a los ciudadanos que viven en el extranjero.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
          '申请所需文件：',
          'Los siguientes documentos son requeridos para la solicitud:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
            '内部护照和/或俄罗斯联邦护照（内部护照优先）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'регистрация в стране проживания',
            'registration in the country of residence',
            '在居住国的注册',
            'registro en el país de residencia',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Акт о личной явке',
          'Act of personal appearance.',
          '个人出席声明',
          'Acta de comparecencia personal.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, подтверждающий, что человек жив, и требуемый для продолжения пенсионных выплат в следующем периоде гражданам, проживающим за рубежом.',
          'A document confirming that a person is alive, required for the continuation of pension payments in the next period for citizens living abroad.',
          '确认一个人仍然活着的文件，要求在下一个阶段继续为在国外生活的公民支付养老金。',
          'Un documento que confirma que una persona está viva, necesario para la continuación de los pagos de pensiones en el siguiente período para los ciudadanos que viven en el extranjero.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
          '申请所需文件：',
          'Los siguientes documentos son requeridos para la solicitud:',
        ],
        items: [
          [
            'загранпаспорт РФ',
            'foreign passport of the Russian Federation',
            '俄罗斯联邦护照',
            'pasaporte extranjero de la Federación Rusa',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о выполнении (невыполнении) оплачиваемой работы за пределами РФ',
          'Certificate of completion (non-completion) of paid work outside the Russian Federation.',
          '在俄罗斯境外完成（未完成）有偿工作的证明',
          'Certificado de finalización (no finalización) de trabajo remunerado fuera de la Federación Rusa.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Одна из справок, которая может потребоваться для оформления пенсионных выплат гражданам, проживающим за рубежом.',
          'One of the certificates that may be required for processing pension payments to citizens living abroad.',
          '这是在国外居住的公民办理养老金支付时可能需要的证明之一。',
          'Uno de los certificados que puede ser necesario para procesar los pagos de pensiones a los ciudadanos que viven en el extranjero.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
          '申请所需文件：',
          'Los siguientes documentos son requeridos para la solicitud:',
        ],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
            '内部护照和/或俄罗斯联邦护照（内部护照优先）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'справка с места работы, официальным способом переведённая на русский язык',
            'certificate from the workplace, officially translated into Russian',
            '工作单位开具的证明，正式翻译成俄文',
            'certificado del lugar de trabajo, traducido oficialmente al ruso',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Документы ЗАГС (свидетельство о рождении, смерти, браке, разводе, смене имени)',
          'Civil Registry Office Documents (birth, death, marriage, divorce, name change certificates).',
          '民事登记处文件（出生、死亡、结婚、离婚、改名证明）。',
          'Documentos de la Oficina del Registro Civil (certificados de nacimiento, muerte, matrimonio, divorcio y cambio de nombre).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не могут быть истребованы повторные свидетельства о заключении брака, если он расторгнут, а также свидетельства о рождении на умерших.',
          'Repeated marriage certificates cannot be requested if the marriage is dissolved, as well as birth certificates for deceased persons.',
          '如果婚姻已解除，则不能请求重复的结婚证明，已故人员的出生证明也不能请求。',
          'No se pueden solicitar certificados de matrimonio repetidos si el matrimonio ha sido disuelto, ni certificados de nacimiento para personas fallecidas.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельства о рождении несовершеннолетних истребуются их законными представителями.',
          'Birth certificates of minors are requested by their legal representatives.',
          '未成年人的出生证明由其法定代表人申请。',
          'Los certificados de nacimiento de menores son solicitados por sus representantes legales.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельства о смерти могут быть истребованы родственниками умерших или юридическими лицами для официальных целей.',
          'Death certificates can be requested by the relatives of the deceased or by legal entities for official purposes.',
          '死亡证明可由死者的亲属或法人出于官方目的申请。',
          'Los certificados de defunción pueden ser solicitados por los familiares del fallecido o por entidades legales con fines oficiales.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о семейном положении',
          'Certificate of marital status.',
          '婚姻状况证明',
          'Certificado de estado civil.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, представляющий собой выписку из единого реестра органов ЗАГС, и подтверждающий, что гражданин на данный момент не состоит в браке.',
          'A document representing an extract from the unified registry of civil status acts, confirming that the citizen is currently not married.',
          '该文件代表从民事状态统一注册处的摘录，确认该公民目前未婚。',
          'Un documento que representa un extracto del registro unificado de actos de estado civil, confirmando que el ciudadano actualmente no está casado.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Требуется для заключения брака с иностранцем.',
          'Required for marriage with a foreigner.',
          '与外国人结婚时需要。',
          'Requerido para el matrimonio con un extranjero.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок истребования может составлять от 2 до 6 месяцев.',
          'The request period may take from 2 to 6 months.',
          '申请的时间可能需要2到6个月。',
          'El plazo de solicitud puede tardar de 2 a 6 meses.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о стаже и трудовой деятельности',
          'Certificate of employment history and work activity.',
          '工作经历和活动证明',
          'Certificado de historial laboral y actividad laboral.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не подлежат истребованию, пересылке и вывозу за границу подлинники трудовых и пенсионных книжек.',
          'Original work and pension books are not subject to request, mailing, or export abroad.',
          '工作和养老金的原件不能请求、邮寄或出口到国外。',
          'Los libros de trabajo y pensiones originales no están sujetos a solicitud, envío o exportación al extranjero.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде за пределы России для проживания указанные документы сдаются в соответствующие организации по месту жительства в России.',
          'When leaving Russia for residence, the specified documents are submitted to the appropriate organizations at the place of residence in Russia.',
          '当离开俄罗斯居住时，将上述文件提交给在俄罗斯居住地的相关机构。',
          'Al salir de Rusia para residir, los documentos especificados se presentan a las organizaciones correspondientes en el lugar de residencia en Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На основании сведений, содержащихся в данных документах, в установленном порядке оформляются справки о стаже и трудовой деятельности.',
          'Based on the information contained in these documents, certificates of employment history and work activity are issued in the prescribed manner.',
          '根据这些文件中包含的信息，按规定程序发放工作经历和活动证明。',
          'Con base en la información contenida en estos documentos, se emiten certificados de historial laboral y actividad laboral de acuerdo con el procedimiento establecido.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о прохождении воинской службы',
          'Certificate of military service.',
          '兵役证明',
          'Certificado de servicio militar.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не подлежат истребованию, пересылке и вывозу за границу военные билеты.',
          'Military IDs are not subject to request, mailing, or export abroad.',
          '军事身份证不适用于申请、邮寄或出口到国外。',
          'Las identificaciones militares no están sujetas a solicitud, envío o exportación al extranjero.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде за пределы России для проживания указанные документы сдаются в соответствующие организации по месту жительства в России.',
          'When leaving Russia for residence, the specified documents are submitted to the appropriate organizations at the place of residence in Russia.',
          '当离开俄罗斯居住时，将上述文件提交给在俄罗斯居住地的相关机构。',
          'Al salir de Rusia para residir, los documentos especificados se presentan a las organizaciones correspondientes en el lugar de residencia en Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На основании сведений, содержащихся в данных документах, в установленном порядке оформляются справки о прохождении воинской службы.',
          'Based on the information contained in these documents, certificates of military service are issued in the prescribed manner.',
          '根据这些文件中包含的信息，按规定程序发放兵役证明。',
          'Con base en la información contenida en estos documentos, se emiten certificados de servicio militar de acuerdo con el procedimiento establecido.',
        ],
      },
    ],
  ],

  workflow: {
    certificateOrDocument: {
      title: ['Что вы хотите оформить?', 'What do you want to arrange?'],
      options: [...serviceTypes, ['Не знаю', 'Not sure']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
        '请用自己的话描述所需的文件',
        'Describe en sus propias palabras qué documento se requiere',
      ],
    },
    certificateType: {
      title: ['Какая справка вам необходима?', 'What certificate do you need?'],
      options: [...certificateTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
        '请用自己的话描述所需的文件',
        'Describe en sus propias palabras qué documento se requiere',
      ],
    },
    documentType: {
      title: [
        'Какой документ вы хотите получить?',
        'What document do you want to obtain?',
      ],
      options: [...documentTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
        '请用自己的话描述所需的文件',
        'Describe en sus propias palabras qué documento se requiere',
      ],
      apostile: ['Заверенный апостилем', 'Apostile', '经过公证的', 'Apostilla'],
    },
    civilRegistryType: {
      title: [
        'Какое свидетельство ЗАГС необходимо получить?',
        'What civil registry certificate do you need to obtain?',
      ],
      options: [
        [
          'о рождении (свое)',
          'Birth certificate (own)',
          '出生证明（自己）',
          'Certificado de nacimiento (propio)',
        ],
        [
          'о рождении (ребенку)',
          'Birth certificate (child)',
          '出生证明（儿童）',
          'Certificado de nacimiento (niño)',
        ],
        [
          'о смерти (родственника)',
          'Death certificate (relative)',
          '死亡证明（亲属）',
          'Certificado de defunción (familiar)',
        ],
        [
          'о браке или разводе',
          'Marriage or divorce certificate',
          '结婚或离婚证明',
          'Certificado de matrimonio o divorcio',
        ],
        [
          'о перемене имени',
          'Name change certificate',
          '改名证明',
          'Certificado de cambio de nombre',
        ],
      ],
    },
    requestAdditionalInformation: {
      title: ['Дополнительные сведения', 'Additional information'],
      additionalInfo: [
        'Опишите своими словами, для каких целей будет использоваться документ, номер и дату выдачи (если имеется)',
        'Describe in your own words, for what purpose the document will be used, the number and date of issuance (if applicable)',
        '请用自己的话描述文件将用于什么目的，以及编号和发行日期（如适用）',
        'Describa en sus propias palabras para qué se utilizará el documento, el número y la fecha de emisión (si corresponde)',
      ],
    },
  },
  requiredDocumentsText: [
    'Базовый комплект документов, которые потребуются для подготовки заявления, включает в себя:',
    'The basic set of documents that will be required to prepare an application includes:',
    '准备申请所需的基本文件包括：',
    'El conjunto básico de documentos que se requerirá para preparar una solicitud incluye:',
  ],
}

export default vocabularyCertificates
