const vocabularyCookiePolicy = [
  [
    {
      title: 'Общие положения',
      paragraphs: [
        'При посещении сайта https://consul.online/ (далее — «Сайт»), Пользователь предоставляет согласие на использование cookie-файлов в соответствии с настоящей политикой в отношении данного типа файлов.',
      ],
    },
    {
      title: 'Что такое файлы cookie',
      paragraphs: [
        'Cookie-файл — это небольшой текстовый файл, который сайт сохраняет на компьютере или мобильном устройстве Пользователя при посещении сайта. Использование данных файлов позволяет обеспечить корректную работу сайта, повысить его эффективность, а также получить информацию о посещении страниц пользователями и их предпочтениях в интернете. Файл cookie не идентифицирует личность, а представляет собой комбинацию информации об устройстве, браузере и Пользователе. В нашем случае эта информация является анонимной и зашифрованной.',
      ],
    },
    {
      title: 'Классификация',
      paragraphs: [
        'Файлы cookie можно классифицировать по следующим параметрам:',
      ],
      subSections: [
        {
          title: 'По отправителю',
          items: [
            'Собственные: Отправляются на устройство Пользователя с оборудования или домена, принадлежащего Сайту',
            'Сторонние: Отправляются на устройство Пользователя с оборудования или домена, управляемого другой организацией, обрабатывающей данные, полученные через файлы cookie',
          ],
        },
        {
          title: 'По сроку хранения',
          items: [
            'Сессионные: Активны с момента входа Пользователя на сайт до завершения сессии работы в браузере и автоматически удаляются при его закрытии',
            'Постоянные: Сохраняются на устройстве между сессиями и не удаляются при закрытии браузера. Данные файлы могут использоваться для определения частоты посещений сайтов и их эффективности. Срок хранения варьируется в зависимости от их назначения от нескольких минут до нескольких лет',
          ],
        },
        {
          title: 'По цели использования',
          items: [
            'Обязательные: Обеспечивают основные функции сайта, такие как навигация по страницам и доступ к защищённым областям. Без этих файлов сайт не сможет работать корректно',
            'Функциональные: Позволяют идентифицировать пользователей, возвращающихся на сайт, и запоминать их предпочтения (например, язык или регион)',
            'Аналитические: Позволяют собирать информацию о том, как посетители используют сайт, улучшать его работу и устранять неполадки в случае их появления. Такие данные собираются в агрегированной и анонимной форме',
            'Рекламные: Используются для того, чтобы предоставлять пользователям рекламу, которая соответствует их интересам, а также измерять эффективность рекламных кампаний',
          ],
        },
      ],
    },
    {
      title: 'Использование',
      paragraphs: [
        'Сайт использует программное обеспечение для определения количества пользователей, посещающих его страницы, и частоты этих посещений. Сайт не собирает персональные данные Пользователей или IP-адреса отдельных лиц. Данные используются исключительно в анонимной форме для статистики и улучшения работы сайта.',
      ],
      subSections: [
        {
          title: 'Исключение',
          items: [
            'cookie-файлы Google Analytics могут использовать IP-адреса для распознавания пользователей, но не для их персональной идентификации. Эти файлы помогают сайту получать данные о его посещаемости и улучшать пользовательский опыт. Пользователь может отказаться от использования Google Analytics, перейдя по следующей ссылке: ',
          ],
        },
      ],
    },
    {
      title: 'Основание',
      paragraphs: [
        'Основание, которое легитимирует обработку в связи с использованием обязательных файлов cookie, — это законный интерес компании «ICC Consul» LLP (KZ), предоставляющей свои услуги посредством сайта, в целях облегчения навигации Пользователя по сайту.',
        'Основание, которое легитимирует обработку в связи с использованием файлов cookie, не необходимых для навигации (функциональные, аналитические и рекламные), — это согласие Пользователя. Пользователь может отозвать это согласие в любое время, что не влияет на законность обработки, выполненной до его отзыва. Для этого Пользователь может ознакомиться со следующим разделом данной Политики о настройке и отключении файлов cookie, а также получить доступ к информации о процедуре реализации прав и другой информации, связанной с обработкой его персональных данных, через Политику конфиденциальности.',
      ],
      subSections: [
        {
          title: 'Управление',
          items: [
            'Большинство браузеров позволяют просматривать, удалять и блокировать cookie-файлы, а также настраивать уведомления об их записи. При этом, в случае удаления всех cookie-файлов произойдёт сброс всех настроек сайтов для Пользователя.',
            'С инструкциями по управлению cookie-файлами для распространённых браузеров можно ознакомиться по ссылкам ниже:',
          ],
        },
        {
          title: 'Информация о других браузерах и типах устройств',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            'Если Пользователь использует несколько разных устройств для доступа к сайту (компьютер, смартфон, планшет), то необходимо убедиться, что настройки браузера на каждом из них соответствуют его предпочтениям.',
          ],
        },
      ],
    },
  ],
  [
    {
      title: 'General provisions',
      paragraphs: [
        'By visiting the website https://consul.online/ (hereinafter referred to as the "Site"), the User consents to the use of cookie files in accordance with this policy.',
      ],
    },
    {
      title: 'What are cookies',
      paragraphs: [
        "A cookie file is a small text file that the website saves on the User's computer or mobile device when visiting the site. The use of these files allows the site to function correctly, enhance its efficiency, and obtain information about user visits and preferences on the internet. A cookie file does not identify the individual but represents a combination of information about the device, browser, and User. In our case, this information is anonymous and encrypted.",
      ],
    },
    {
      title: 'Classification',
      paragraphs: [
        'Cookie files can be classified according to the following parameters:',
      ],
      subSections: [
        {
          title: 'By sender',
          items: [
            "First-party: Sent to the User's device from equipment or a domain owned by the Site.",
            "Third-party: Sent to the User's device from equipment or a domain managed by another organization processing data obtained through cookie files.",
          ],
        },
        {
          title: 'By storage period',
          items: [
            'Session cookies: Active from the moment the User enters the site until the end of the browser session and automatically deleted when the browser is closed.',
            'Persistent cookies: Stored on the device between sessions and not deleted when the browser is closed. These files may be used to determine the frequency of site visits and their effectiveness. The storage period varies depending on their purpose from a few minutes to several years.',
          ],
        },
        {
          title: 'By purpose of use',
          items: [
            'Essential: Ensure basic site functions such as navigating pages and accessing secure areas. Without these files, the site may not function properly.',
            'Functional: Allow identifying returning users and remembering their preferences (e.g., language or region).',
            'Analytical: Gather information on how visitors use the site, improve its performance, and address issues if they arise. Such data is collected in aggregated and anonymous form.',
            'Advertising: Used to provide users with ads that match their interests and measure the effectiveness of advertising campaigns.',
          ],
        },
      ],
    },
    {
      title: 'Usage',
      paragraphs: [
        'The site uses software to determine the number of users visiting its pages and the frequency of these visits. The site does not collect personal data or IP addresses of individuals. Data is used exclusively in anonymous form for statistics and site performance improvement.',
      ],
      subSections: [
        {
          title: 'Exception',
          items: [
            'Google Analytics cookies may use IP addresses to recognize users but not for their personal identification. These files help the site obtain data on its traffic and improve user experience. Users can opt-out of Google Analytics by visiting the following link:',
          ],
        },
      ],
    },
    {
      title: 'Legal Basis',
      paragraphs: [
        'The legal basis legitimizing the processing related to the use of essential cookie files is the legitimate interest of the company "ICC Consul" LLP (KZ), providing its services through the site, to facilitate User navigation on the site.',
        'The legal basis legitimizing the processing related to the use of non-essential cookie files (functional, analytical, and advertising) is User consent. The User may withdraw this consent at any time, which does not affect the legality of the processing performed before its withdrawal. For this, the User can refer to the following section of this Cookie Policy on setting and disabling cookie files, as well as access information on the procedure for exercising rights and other information related to the processing of their personal data through the Privacy Policy.',
      ],
      subSections: [
        {
          title: 'Management',
          items: [
            'Most browsers allow you to view, delete, and block cookie files, as well as customize notifications about their storage. Deleting all cookie files will reset all site settings for the User.',
            'Instructions for managing cookie files for popular browsers can be found at the links below:',
          ],
        },
        {
          title: 'Information about other browsers and device types',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            'If the User uses multiple different devices to access the site (computer, smartphone, tablet), ensure that browser settings on each of them match their preferences.',
          ],
        },
      ],
    },
  ],
  [
    {
      title: '一般条款',
      paragraphs: [
        '访问网站 https://consul.online/（以下简称“网站”）时，用户同意根据本政策使用 cookie 文件。',
      ],
    },
    {
      title: '什么是 Cookie 文件',
      paragraphs: [
        'Cookie 文件是网站在用户访问网站时保存到用户计算机或移动设备上的小型文本文件。使用这些文件可以确保网站正常运行，提高其效率，并获取有关用户访问及其在线偏好的信息。Cookie 文件并不识别个人，而是设备、浏览器和用户信息的组合。在我们的情况下，这些信息是匿名和加密的。',
      ],
    },
    {
      title: '分类',
      paragraphs: [
        'Cookie 文件可以根据以下参数进行分类：',
      ],
      subSections: [
        {
          title: '按发送者',
          items: [
            '第一方: 从网站所有的设备或域发送到用户的设备。',
            '第三方: 从其他组织处理通过 Cookie 文件获取的数据的设备或域发送到用户的设备。',
          ],
        },
        {
          title: '按存储期限',
          items: [
            '会话 Cookie: 从用户进入网站的那一刻起到浏览器会话结束，关闭浏览器时自动删除。',
            '持久 Cookie: 在会话之间保存在设备上，关闭浏览器时不会删除。这些文件可用于确定访问网站的频率及其有效性。存储期限根据其用途从几分钟到几年不等。',
          ],
        },
        {
          title: '按用途分类',
          items: [
            '必要的: 确保网站的基本功能，如页面导航和访问安全区域。没有这些文件，网站可能无法正常工作。',
            '功能性: 允许识别返回网站的用户，并记住他们的偏好（例如语言或地区）。',
            '分析性: 允许收集访客使用网站的信息，提高其性能，并在出现问题时解决。此类数据以聚合和匿名的形式收集。',
            '广告: 用于向用户提供与其兴趣相匹配的广告，并测量广告活动的有效性。',
          ],
        },
      ],
    },
    {
      title: '使用',
      paragraphs: [
        '网站使用软件来确定访问其页面的用户数量及其访问频率。网站不收集个人数据或个人的 IP 地址。数据仅以匿名形式用于统计和网站性能改进。',
      ],
      subSections: [
        {
          title: '例外',
          items: [
            'Google Analytics 的 Cookie 文件可能会使用 IP 地址来识别用户，但不用于个人身份识别。这些文件帮助网站获取流量数据并改善用户体验。用户可以通过访问以下链接选择退出 Google Analytics：',
          ],
        },
      ],
    },
    {
      title: '法律依据',
      paragraphs: [
        '使用必要 Cookie 文件的处理合法性基础是 "ICC Consul" 有限责任公司（KZ）的合法利益，该公司通过网站提供服务，旨在方便用户在网站上的导航。',
        '使用非必要 Cookie 文件（功能性、分析性和广告性）的处理合法性基础是用户的同意。用户可以随时撤回此同意，这不会影响撤回之前处理的合法性。为此，用户可以查阅本 Cookie 政策的下一部分，了解如何设置和禁用 Cookie 文件，并通过隐私政策获取有关行使权利的程序及与其个人数据处理相关的其他信息。',
      ],
      subSections: [
        {
          title: '管理',
          items: [
            '大多数浏览器允许查看、删除和阻止 Cookie 文件，并自定义有关其存储的通知。删除所有 Cookie 文件时，网站的所有设置将重置为用户。',
            '以下链接提供了热门浏览器管理 Cookie 文件的说明：',
          ],
        },
        {
          title: '关于其他浏览器和设备类型的信息',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            '如果用户使用多种不同设备访问网站（计算机、智能手机、平板电脑），必须确保每个设备上的浏览器设置符合他们的偏好。',
          ],
        },
      ],
    },
  ],
  [
    {
      title: 'Disposiciones generales',
      paragraphs: [
        'Al visitar el sitio web https://consul.online/ (en adelante, el "Sitio"), el Usuario consiente el uso de archivos de cookies de acuerdo con esta política.',
      ],
    },
    {
      title: '¿Qué son los archivos de cookies?',
      paragraphs: [
        'Un archivo de cookie es un pequeño archivo de texto que el sitio web guarda en la computadora o dispositivo móvil del Usuario cuando visita el sitio. El uso de estos archivos permite que el sitio funcione correctamente, mejore su eficiencia y obtenga información sobre las visitas y preferencias de los usuarios en internet. Un archivo de cookie no identifica a la persona, sino que representa una combinación de información sobre el dispositivo, el navegador y el Usuario. En nuestro caso, esta información es anónima y está cifrada.',
      ],
    },
    {
      title: 'Clasificación',
      paragraphs: [
        'Los archivos de cookies se pueden clasificar según los siguientes parámetros:',
      ],
      subSections: [
        {
          title: 'Por remitente',
          items: [
            'Propios: Enviados al dispositivo del Usuario desde el equipo o dominio propiedad del Sitio.',
            'De terceros: Enviados al dispositivo del Usuario desde el equipo o dominio gestionado por otra organización que procesa los datos obtenidos a través de los archivos de cookies.',
          ],
        },
        {
          title: 'Por periodo de almacenamiento',
          items: [
            'Cookies de sesión: Activos desde el momento en que el Usuario entra en el Sitio hasta el final de la sesión del navegador y se eliminan automáticamente al cerrar el navegador.',
            'Cookies permanentes: Se almacenan en el dispositivo entre sesiones y no se eliminan al cerrar el navegador. Estos archivos pueden utilizarse para determinar la frecuencia de visitas al Sitio y su efectividad. El período de almacenamiento varía según su propósito, desde unos minutos hasta varios años.',
          ],
        },
        {
          title: 'Por propósito de uso',
          items: [
            'Esenciales: Garantizan las funciones básicas del Sitio, como la navegación por páginas y el acceso a áreas seguras. Sin estos archivos, el Sitio puede no funcionar correctamente.',
            'Funcionales: Permiten identificar a los usuarios que regresan al Sitio y recordar sus preferencias (por ejemplo, idioma o región).',
            'Analíticos: Permiten recopilar información sobre cómo los visitantes utilizan el Sitio, mejorar su rendimiento y resolver problemas si surgen. Estos datos se recopilan de forma agregada y anónima.',
            'Publicitarios: Se utilizan para ofrecer a los usuarios anuncios que coinciden con sus intereses y medir la efectividad de las campañas publicitarias.',
          ],
        },
      ],
    },
    {
      title: 'Uso',
      paragraphs: [
        'El Sitio utiliza software para determinar el número de Usuarios que visitan sus páginas y la frecuencia de estas visitas. El Sitio no recopila datos personales ni direcciones IP de personas individuales. Los datos se utilizan exclusivamente en forma anónima para estadísticas y mejora del rendimiento del Sitio.',
      ],
      subSections: [
        {
          title: 'Excepción',
          items: [
            'Las cookies de Google Analytics pueden utilizar direcciones IP para reconocer a los Usuarios, pero no para su identificación personal. Estos archivos ayudan al Sitio a obtener datos sobre su tráfico y mejorar la experiencia del usuario. Los Usuarios pueden optar por no participar en Google Analytics visitando el siguiente enlace:',
          ],
        },
      ],
    },
    {
      title: 'Base legal',
      paragraphs: [
        'La base legal que legitima el procesamiento relacionado con el uso de archivos de cookies esenciales es el interés legítimo de la empresa "ICC Consul" LLP (KZ), que presta sus servicios a través del Sitio para facilitar la navegación de los Usuarios en el Sitio.',
        'La base legal que legitima el procesamiento relacionado con el uso de archivos de cookies no esenciales (funcionales, analíticos y publicitarios) es el consentimiento del Usuario. El Usuario puede retirar este consentimiento en cualquier momento, lo que no afecta la legalidad del procesamiento realizado antes de su retiro. Para ello, el Usuario puede consultar la siguiente sección de esta Política de Cookies sobre la configuración y desactivación de archivos de cookies, así como acceder a información sobre el procedimiento para ejercer derechos y otra información relacionada con el procesamiento de sus datos personales a través de la Política de Privacidad.',
      ],
      subSections: [
        {
          title: 'Gestión',
          items: [
            'La mayoría de los navegadores permiten ver, eliminar y bloquear archivos de cookies, así como personalizar las notificaciones sobre su almacenamiento. Al eliminar todos los archivos de cookies, se restablecerán todas las configuraciones del Sitio para el Usuario.',
            'A continuación, se proporcionan enlaces con instrucciones para gestionar los archivos de cookies en navegadores populares:',
          ],
        },
        {
          title: 'Información sobre otros navegadores y tipos de dispositivos',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            'Si el Usuario utiliza varios dispositivos diferentes para acceder al Sitio (computadora, teléfono inteligente, tableta), debe asegurarse de que la configuración del navegador en cada uno de ellos coincida con sus preferencias.',
          ],
        },
      ],
    },
  ]
  
  
]
export default vocabularyCookiePolicy