import { useContext } from 'react'
import GlobalContext from 'GlobalContext'
import { Box, InputAdornment, TextField, Autocomplete } from '@mui/material/'
import { Search as SearchIcon } from '@mui/icons-material'
import vocabulary from 'vocabulary/vocabulary'
import { SearchModal } from './SearchModal.desktop'
import { options } from 'vocabulary/vocabularySearch'
import { useOnInputValueChange } from './hooks/useOnInputValueChange'

export const SearchInput = ({ resolution }) => {
  const { langSelected, state } = useContext(GlobalContext)
  const [value, onInputValueChange] = useOnInputValueChange()

  return (
    <>
      <Box>
        <Autocomplete
          value={value || ''}
          onChange={onInputValueChange}
          freeSolo
          id='search-input'
          selectOnFocus
          clearOnBlur
          options={options[langSelected]}
          renderInput={params => (
            <TextField
              {...params}
              id='search-input-field'
              placeholder={vocabulary.search[langSelected]}
              variant='outlined'
              margin='dense'
              InputProps={{
                ...params.InputProps,
                sx: {
                  ml: '106px',
                  mr: '16px',
                  minWidth:
                    resolution === '1439'
                      ? state.menuSelected === 2
                        ? 500
                        : 720
                      : resolution === '1239'
                        ? 568
                        : 188,
                  height: 56,
                  borderRadius: 25,
                  backgroundColor: '#E1E2E8',
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <SearchModal />
    </>
  )
}
