import {
  Baby,
  IdentificationCard,
  AddressBookTabs,
} from '@phosphor-icons/react'

import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import marriageRingsImage from '../images/marriage-black.png'

export const serviceTypes = [
  ['Рождение ребенка', 'Childbirth', '孩子出生', 'Nacimiento de un niño'],
  ['Усыновление (удочерение) ребенка', 'Adoption', '收养', 'Adopción'],
  [
    'Установление отцовства',
    'Establishment of paternity',
    '父亲身份的确认',
    'Establecimiento de la paternidad',
  ],
  ['Заключение брака', 'Marriage', '结婚', 'Matrimonio'],
  ['Расторжение брака', 'Divorce', '离婚', 'Divorcio'],
  ['Перемена имени', 'Name change', '改名', 'Cambio de nombre'],
  [
    'Регистрация смерти',
    'Death registration',
    '死亡登记',
    'Registro de defunción',
  ],
]

const vocabularyZags = {
  zagsServices: ['Услуги ЗАГС', 'ZAGS Services'],
  infoText1: [
    'В консульстве или посольстве России граждане могут воспользоваться услугами ЗАГС: зарегистрировать рождение ребёнка, брак, развод или смерть близкого.',
    'At the Russian consulate or embassy, ​​citizens can use the services of a registry office: to register the birth of a child, marriage, divorce or death of a loved one.',
    '在俄罗斯领事馆或大使馆，公民可以使用登记处的服务：注册孩子的出生、结婚、离婚或亲人的死亡。',
    'En el consulado o embajada rusa, los ciudadanos pueden utilizar los servicios de un registro civil: registrar el nacimiento de un niño, matrimonio, divorcio o muerte de un ser querido。',
  ],
  infoText2: [
    'Мы будем рады помочь вам на каждом этапе взаимодействия с консульством: подготовим за вас заявление в нужном формате на консульском портале, проконсультируем по вашей ситуации и перечню документов, которые необходимо будет взять с собой, при необходимости окажем помощь в записи на приём в консульство.',
    'We will be happy to help you at every stage of interaction with the consulate: we will prepare an application for you in the required format on the consular portal, we will advise you on your situation and the list of documents that you will need to take with you, and, if necessary, we will assist you in making an appointment at the consulate.',
    '我们将很高兴在您与领事馆互动的每个阶段为您提供帮助：我们将为您准备所需格式的申请，在领事馆门户网站上提供建议，并协助您了解您需要携带的文件清单，如有必要，我们将协助您预约领事馆。',
    'Estaremos encantados de ayudarle en cada etapa de la interacción con el consulado: prepararemos una solicitud para usted en el formato requerido en el portal consular, le asesoraremos sobre su situación y la lista de documentos que necesitará llevar con usted y, si es necesario, le ayudaremos a concertar una cita en el consulado。',
  ],
  zagsServicesConsulate: [
    'Услуги ЗАГС, оказываемые консульством',
    'Civil registry services provided by the consulate',
    '民政登记处提供的服务',
    'Servicios de registro civil proporcionados por el consulado',
  ],
  typesOfDocuments: [
    {
      section: [
        'Услуги ЗАГС, оказываемые консульством',
        'Civil registry services provided by the consulate',
        '民政登记处提供的服务',
        'Servicios de registro civil proporcionados por el consulado',
      ],
      types: [
        {
          service: serviceTypes[0][1],
          text: [
            'Рождение ребёнка',
            'Birth of a child',
            '孩子出生',
            'Nacimiento de un niño',
          ],
          icon: <Baby size={24} weight='light' />,
        },
        {
          service: serviceTypes[1][1],
          text: ['Усыновление / удочерение', 'Adoption', '收养', 'Adopción'],
          icon: <Baby size={24} weight='light' />,
        },
        {
          service: serviceTypes[2][1],
          text: [
            'Установление отцовства',
            'Establishing paternity',
            '父亲身份的确认',
            'Establecimiento de la paternidad',
          ],
          icon: <Baby size={24} weight='light' />,
        },
        {
          service: serviceTypes[3][1],
          text: ['Заключение брака', 'Marriage', '结婚', 'Matrimonio'],
          icon: <img src={marriageRingsImage} alt='marriage-ring' width={24} />,
        },
        {
          service: serviceTypes[4][1],
          text: ['Расторжение брака', 'Divorce', '离婚', 'Divorcio'],
          icon: <img src={marriageRingsImage} alt='marriage-ring' width={24} />,
        },
        {
          service: serviceTypes[5][1],
          text: ['Перемена имени', 'Name change', '改名', 'Cambio de nombre'],
          icon: <IdentificationCard size={24} weight='light' />,
        },
        {
          service: serviceTypes[6][1],
          text: [
            'Регистрация смерти',
            'Death registration',
            '死亡登记',
            'Registro de defunción',
          ],
          icon: <AddressBookTabs size={24} weight='light' />,
        },
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Выберите нужную вам консульскую услугу и опишите вашу ситуацию. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали и заполнит онлайн-заявление на консульском портале. Вы получите заполненный документ, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с пакетом документов для подачи на получение услуги ЗАГС',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Select the consular service you need and describe your situation. You can send us the required documents either simultaneously with the application or directly to the lawyer after consultation with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft document and post it on the CD portal of the Russian Foreign Ministry. You will receive an accommodation number, which you will need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with a package of documents to submit for the registry office service',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '提交申请',
        cardText:
          '选择您需要的领事服务并描述您的情况。您可以在提交申请时同时发送所需的文件，也可以在与律师咨询后直接发送给他。',
        cardImage: checkBoxImage,
      },
      {
        headerText: '准备文件文本',
        cardText:
          '我们的律师将与您联系，讨论细节，准备草拟文件并将其发布在俄罗斯外交部的CD门户网站上。您将获得一个登记号码，您需要在前往领事馆时携带。服务完成时间为付款后2个工作日。',
        cardImage: documentBrown,
      },
      {
        headerText: '在领事馆预约',
        cardText:
          '您可以自行预约领事馆，或在我们的协助下进行预约（根据所选套餐）',
        cardImage: calendarImage,
      },
      {
        headerText: '获取准备好的文件',
        cardText: '在登记当天，携带全部文件到领事馆进行提交注册服务',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentación de una solicitud',
        cardText:
          'Seleccione el servicio consular que necesita y describa su situación. Puede enviarnos los documentos requeridos, ya sea simultáneamente con la solicitud o directamente al abogado después de consultarlo.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparación del texto del documento',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles, preparará un borrador del documento y lo publicará en el portal CD del Ministerio de Relaciones Exteriores de Rusia. Recibirá un número de alojamiento, que deberá llevar consigo al consulado. El tiempo de finalización del servicio es de 2 días hábiles a partir de la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programación de citas en el consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra ayuda (según la tarifa seleccionada)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Recepción del documento listo',
        cardText:
          'El día de la inscripción, acuda al consulado con un paquete de documentos para presentar la solicitud del servicio del registro civil',
        cardImage: stampImage,
      },
    ],
  ],

  infoText3: [
    'Если вам требуется получить дубликат документа из ЗАГС (в том числе с апостилем) или справку о семейном положении для заключения брака с иностранцем, перейдите в раздел:',
    'If you need to obtain a duplicate document from the registry office (including with an apostille) or a certificate of marital status for marriage with a foreigner, go to the following section:',
    '如果您需要从注册处获得文件的副本（包括公证），或者为了与外国人结婚而需要结婚状况证明，请转到以下部分：',
    'Si necesita obtener un duplicado de un documento del registro civil (incluido con un apostille) o un certificado de estado civil para casarse con un extranjero, vaya a la siguiente sección:',
  ],

  documentRequest: [
    'Истребование документов',
    'Request for documents',
    '文件请求',
    'Solicitud de documentos',
  ],

  infoText4: [
    'Базовые комплекты документов, представляемые в консульство / посольство России для получения услуг ЗАГС:',
    'Basic sets of documents submitted to the Russian consulate/embassy to receive civil registry services:',
    '提交给俄罗斯领事馆/大使馆以获取公民登记服务的基本文件集：',
    'Conjuntos básicos de documentos presentados en el consulado/embajada de Rusia para recibir servicios de registro civil:',
  ],

  userTypes: [
    {
      userName: [
        'Рождение ребенка',
        'Birth of a child',
        '孩子出生',
        '出生的孩子',
      ],
      desc: [
        [
          'совместное заявление от родителей, находящихся в браке на момент рождения ребёнка, либо от одного из родителей, если он является единственным или не состоит в браке со вторым родителем на момент рождения ребёнка',
          'a joint application from the parents who are married at the time of the child’s birth, or from one of the parents if he is the only one or is not married to the second parent at the time of the child’s birth',
          '父母在孩子出生时结婚的共同申请，或来自其中一位父母的申请，如果他是唯一的或在孩子出生时未与第二位父母结婚',
          '父母在孩子出生时结婚的共同申请，或来自其中一位父母的申请，如果他是唯一的或在孩子出生时未与第二位父母结婚',
        ],
        [
          'загранпаспорта и/или внутренние паспорта обоих родителей или единственного родителя. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'International passports and/or internal passports of both parents or a single parent. If you have both valid passports, you must provide both',
          '双方父母或单一父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '双方父母或单一父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
        [
          'медицинская справка о рождении ребёнка от организации, в которой проходили роды, должным образом заверенная и переведённая на русский язык (при необходимости)',
          'medical certificate about the birth of a child from the organization where the birth took place, duly certified and translated into Russian (if necessary)',
          '来自生产机构的孩子出生医学证明，已适当认证并在必要时翻译成俄语',
          '来自生产机构的孩子出生医学证明，已适当认证并在必要时翻译成俄语',
        ],
        [
          'свидетельство о браке или совместное заявление родителей об установлении отцовства (если в браке не состоят)',
          'marriage certificate or joint statement of parents establishing paternity (if they are not married)',
          '结婚证或父母共同声明确立父亲身份（如果他们没有结婚）',
          '结婚证或父母共同声明确立父亲身份（如果他们没有结婚）',
        ],
      ],
    },
    {
      userName: ['Усыновление / удочерение', 'Adoption', '收养/领养', '收养'],
      desc: [
        [
          'заявление от лица усыновителей (усыновителя)',
          'statement on behalf of the adoptive parents (adoptive parent)',
          '收养父母（养父母）提交的声明',
          '收养父母（养父母）提交的声明',
        ],
        [
          'решение суда об усыновлении ребёнка',
          'court decision on adoption of a child',
          '法院关于收养儿童的决定',
          '法院关于收养儿童的决定',
        ],
        [
          'загранпаспорта и/или внутренние паспорта усыновителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of adoptive parents. If you have both valid passports, you must provide both',
          '收养父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '收养父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
      ],
    },
    {
      userName: [
        'Установление отцовства',
        'Establishing paternity',
        '确定父亲身份',
        '确立父权',
      ],
      desc: [
        [
          'совместное заявление отца и матери ребёнка, не состоящих в браке на момент рождения ребёнка, или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'a joint application from the father and mother of the child who were not married at the time of the child’s birth, or two separate ones if one of the applicants is unable to appear at the consulate in person. In this case, the signature on his application must be notarized',
          '孩子父亲和母亲共同提交的申请，未婚时生育的孩子，或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
          '孩子父亲和母亲共同提交的申请，未婚时生育的孩子，或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
        ],
        [
          'заявление отца ребёнка, не состоящего в браке с его матерью на момент рождения ребёнка, в случае смерти матери, признание её недееспособной, отсутствия сведений о месте её пребывания или лишения её родительских прав, а также при наличии согласия органа опеки и попечительства',
          'statement of the child’s father, who is not married to his mother at the time of the child’s birth, in the event of the mother’s death, recognition of her as legally incompetent, lack of information about her place of stay or deprivation of her parental rights, as well as with the consent of the guardianship and trusteeship authority',
          '孩子的父亲在孩子出生时未与其母亲结婚的情况下提交的声明，母亲去世，或被认为是无行为能力，缺乏其居住地信息或被剥夺其监护权，且在监护和托管机构同意的情况下',
          '孩子的父亲在孩子出生时未与其母亲结婚的情况下提交的声明，母亲去世，或被认为是无行为能力，缺乏其居住地信息或被剥夺其监护权，且在监护和托管机构同意的情况下',
        ],
        [
          'заявление на основании решения суда об установлении отцовства или об установлении факта признания отцовства, вступившее в законную силу',
          'an application based on a court decision to establish paternity or to establish the fact of recognition of paternity, which has entered into legal force',
          '基于法院决定确定父权或确定承认父权的事实的申请，已生效',
          '基于法院决定确定父权或确定承认父权的事实的申请，已生效',
        ],
      ],

      headerDesc: [
        [
          'Один из следующих документов:',
          'One of the following documents:',
          '以下文件之一:',
          '以下文件之一:',
        ],
        ['А также:', 'And:', '还有:', '还有:'],
      ],
      addDesc: [
        [
          'свидетельство о рождении ребёнка',
          "child's birth certificate",
          '孩子的出生证明',
          '孩子的出生证明',
        ],
        [
          'загранпаспорта и/или внутренние паспорта обоих родителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'International passports and/or internal passports of both parents. If you have both valid passports, you must provide both',
          '双方父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '双方父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
        [
          'согласие ребёнка, если он достиг совершеннолетия',
          'consent of the child if he has reached the age of majority',
          '如果孩子已成年，则需征得孩子的同意',
          '如果孩子已成年，则需征得孩子的同意',
        ],
      ],
    },
    {
      userName: ['Заключение брака', 'Marriage', '结婚', '结婚'],
      desc: [
        [
          'совместное заявление или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'a joint application or two separate ones, if one of the applicants is unable to appear at the consulate in person. In this case, the signature on his application must be notarized',
          '共同申请或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
          '共同申请或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
        ],
        [
          'загранпаспорта и/или внутренние паспорта усыновителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of adoptive parents. If you have both valid passports, you must provide both',
          '收养父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '收养父母的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
        [
          'свидетельство о расторжении брака, если кто‑либо из заявителей ранее состоял в браке',
          'certificate of divorce, if any of the applicants was previously married',
          '离婚证，如果任何申请人曾经结过婚',
          '离婚证，如果任何申请人曾经结过婚',
        ],
      ],
    },
    {
      userName: ['Расторжение брака', 'Divorce', '离婚', '离婚'],
      desc: [
        [
          'при обоюдном согласии: совместное заявление или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'with mutual consent: a joint application or two separate ones, if one of the applicants cannot appear at the consulate in person. In this case, the signature on his application must be notarized',
          '在双方同意的情况下：共同申请或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
          '在双方同意的情况下：共同申请或两个单独的申请，如果申请人之一无法亲自出现在领事馆。在这种情况下，申请上的签名必须经过公证',
        ],
        [
          'при наличии решения суда о расторжении брака: заявление супругов (или одного супруга) и решение суда',
          'if there is a court decision on divorce: application of the spouses (or one spouse) and court decision',
          '如果有法院的离婚决定：配偶（或一位配偶）的申请和法院决定',
          '如果有法院的离婚决定：配偶（或一位配偶）的申请和法院决定',
        ],
        [
          'при подаче одним из супругов: заявление и решение (приговор) суда, в котором другой супруг признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет',
          'when filed by one of the spouses: an application and a decision (sentence) of the court in which the other spouse is declared missing, incompetent or sentenced to a term of more than 3 years',
          '当一位配偶提出申请时：一位配偶的申请和法院的判决（判决），另一位配偶被宣告失踪、无行为能力或被判处超过3年的刑期',
          '当一位配偶提出申请时：一位配偶的申请和法院的判决（判决），另一位配偶被宣告失踪、无行为能力或被判处超过3年的刑期',
        ],
      ],

      headerDesc: [
        [
          'Один из следующих документов:',
          'One of the following documents:',
          '以下文件之一:',
          '以下文件之一:',
        ],
        ['А также:', 'And:', '还有:', '还有:'],
      ],
      addDesc: [
        [
          'загранпаспорта и/или внутренние паспорта заявителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of applicants. If you have both valid passports, you must provide both',
          '申请人的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '申请人的国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
        ['свидетельство о браке', 'Marriage certificate', '结婚证', '结婚证'],
      ],
    },
    {
      userName: ['Перемена имени', 'Name change', '更改姓名', '更改姓名'],
      desc: [
        [
          'заявление о перемене имени',
          'application for name change',
          '姓名变更申请',
          '姓名变更申请',
        ],
        [
          'загранпаспорт и/или внутренний паспорт. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passport and/or internal passport. If you have both valid passports, you must provide both',
          '国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
          '国际护照和/或国内护照。如果您有两个有效护照，则必须提供两个',
        ],
        [
          'свидетельство о рождении',
          'birth certificate',
          '出生证明',
          '出生证明',
        ],
        [
          'свидетельство о браке (если заявитель в нем состоит)',
          'marriage certificate (if the applicant is a member of it)',
          '结婚证（如果申请人是其成员）',
          '结婚证（如果申请人是其成员）',
        ],
        [
          'свидетельство о расторжении брака (если заявитель желает вернуть добрачную фамилию)',
          'certificate of divorce (if the applicant wishes to return the premarital surname)',
          '离婚证（如果申请人希望恢复婚前姓氏）',
          '离婚证（如果申请人希望恢复婚前姓氏）',
        ],
        [
          'свидетельство о рождении каждого несовершеннолетнего ребёнка заявителя',
          'birth certificate of each minor child of the applicant',
          '申请人每个未成年子女的出生证明',
          '申请人每个未成年子女的出生证明',
        ],
        [
          'согласие родителей или законных представителей или решение суда при их отсутствии (для детей от 14 до 18 лет)',
          'consent of parents or legal representatives or a court decision in their absence (for children from 14 to 18 years old)',
          '父母或法定代表人的同意，或在缺席的情况下的法院判决（对于14至18岁的儿童）',
          '父母或法定代表人的同意，或在缺席的情况下的法院判决（对于14至18岁的儿童）',
        ],
        [
          'решения российского органа опеки и попечительства (для детей младше 14 лет)',
          'decisions of the Russian guardianship and trusteeship authority (for children under 14 years old)',
          '俄罗斯监护和托管机构的决定（对于14岁以下的儿童）',
          '俄罗斯监护和托管机构的决定（对于14岁以下的儿童）',
        ],
      ],
    },
    {
      userName: [
        'Регистрация смерти',
        'Death registration',
        '死亡登记',
        '死亡登记',
      ],
      desc: [
        ['заявление', 'statement', '声明', '声明'],
        [
          'документ, подтверждающий факт смерти, выданный медицинской организацией или частнопрактикующим врачом, либо решения суда об установлении факта смерти или об объявлении лица умершим',
          'a document confirming the fact of death, issued by a medical organization or a private practitioner, or a court decision to establish the fact of death or to declare a person deceased',
          '确认死亡事实的文件，由医疗机构或私人医生出具，或法院决定确认死亡事实或宣布某人去世',
          '确认死亡事实的文件，由医疗机构或私人医生出具，或法院决定确认死亡事实或宣布某人去世',
        ],
        [
          'загранпаспорт и/или внутренний паспорт умершего (загранпаспорт). Если имеются в наличии оба паспорта, то нужно предоставить оба',
          'international passport and/or internal passport of the deceased (foreign passport). If you have both passports, then you need to provide both',
          '死者的国际护照和/或国内护照（外国护照）。如果您有两个护照，则必须提供两个',
          '死者的国际护照和/或国内护照（外国护照）。如果您有两个护照，则必须提供两个',
        ],
        [
          'загранпаспорт и/или внутренний паспорт лица, сделавшего заявление о смерти',
          'international passport and/or internal passport of the person who made the statement of death',
          '提交死亡声明的人的国际护照和/或国内护照',
          '提交死亡声明的人的国际护照和/或国内护照',
        ],
      ],
    },
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60-80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60-80',
    },

    {
      header: '没有预约的领事馆',
      list: [
        '我们将在领事馆门户网站上填写申请表（和问卷）',
        '我们将根据您的情况为您提供所需文件清单的咨询',
        '我们将就如何自行预约领事馆的预约提供咨询',
      ],
      value: '60-80',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'llenaremos la solicitud (y el cuestionario) en el portal consular',
        'le asesoraremos sobre la lista de documentos que deben ser proporcionados, según su situación',
        'le asesoraremos sobre cómo programar una cita en el consulado por su cuenta',
      ],
      value: '60-80',
    },
  ],

  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'запишем вас на приём в консульство',
      ],
      value: '80-100',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '80-100',
    },

    {
      header: '有预约的领事馆',
      list: [
        '我们将在领事馆门户网站上填写申请表（和问卷）',
        '我们将根据您的情况为您提供所需文件清单的咨询',
        '我们将为您预约领事馆的接待',
      ],
      value: '80-100',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'llenaremos la solicitud (y el cuestionario) en el portal consular',
        'le asesoraremos sobre la lista de documentos que deben ser proporcionados, según su situación',
        'le programaremos una cita en el consulado',
      ],
      value: '80-100',
    },
  ],

  faqDesc: [
    [
      {
        question: 'Можно ли в консульстве зарегистрировать брак с иностранцем?',
        answer:
          'Нет, консульство не регистрирует браки российских граждан с гражданами других государств. Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
      },
      {
        question:
          'Может ли заявление в консульство подать кто‑то один из супругов?',
        answer:
          'Да, в таком случае возможно оформить 2 отдельных заявления, при этом подпись отсутствующего лица должна быть нотариально заверена. На саму процедуру регистрации брака должны явиться оба брачующихся.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
      },
      {
        question: 'Можно ли в консульстве развестись?',
        answer:
          'Да, если оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу, либо есть решение российского суда о разводе. Также один из супругов может подать на развод, если второй супруг судом признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет.',
      },
      {
        question:
          'Можно ли пожениться раньше, чем через месяц после подачи заявления?',
        answer:
          'Да, если на то есть уважительные причины или особые обстоятельства: беременность, рождение ребёнка, непосредственная угроза жизни одной из сторон и другие.',
      },
    ],

    [
      {
        question:
          'Is it possible to register a marriage with a foreigner at the consulate?',
        answer:
          'No, the consulate does not register marriages of Russian citizens with citizens of other states. Such marriages should be concluded in civil registry offices on the territory of Russia, or in similar departments abroad.',
      },
      {
        question:
          'Can one of the spouses submit an application to the consulate?',
        answer:
          'Yes, in this case it is possible to issue 2 separate applications, and the signature of the absent person must be notarized. Both spouses must appear for the marriage registration procedure itself.',
      },
      {
        question:
          'Can I affix an apostille to a registry office document at the consulate?',
        answer:
          'No, the consulate cannot certify with an apostille documents issued in the Russian Federation. However, through the consulate you can request a repeat civil registry office document from Russia with an apostille already affixed to it.',
      },
      {
        question: 'Is it possible to get a divorce at the consulate?',
        answer:
          'Yes, if both spouses agree to divorce, and they do not have common minor children or financial claims against each other, or there is a Russian court decision on divorce. Also, one of the spouses can file for divorce if the second spouse is recognized by the court as missing, incompetent, or sentenced to a term of more than 3 years.',
      },
      {
        question:
          'Is it possible to get married earlier than a month after submitting the application?',
        answer:
          'Yes, if there are good reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
      },
    ],

    [
      {
        question: '在领事馆可以与外国人登记结婚吗？',
        answer:
          '不可以，领事馆不登记俄罗斯公民与其他国家公民的婚姻。这类婚姻应在俄罗斯境内的民事登记处或在海外的类似部门进行。',
      },
      {
        question: '夫妻中的一方可以向领事馆提交申请吗？',
        answer:
          '可以，在这种情况下，可以提交两个单独的申请，缺席者的签名必须经过公证。两个配偶都必须出席婚姻登记程序。',
      },
      {
        question: '我可以在领事馆对登记处的文件加盖公证吗？',
        answer:
          '不可以，领事馆不能对在俄罗斯联邦签发的文件加盖公证。然而，您可以通过领事馆请求从俄罗斯获取一份已加盖公证的重复登记处文件。',
      },
      {
        question: '在领事馆可以离婚吗？',
        answer:
          '可以，如果双方同意离婚，并且他们没有共同的未成年子女或财务索赔，或者有俄罗斯法院的离婚裁决。如果第二个配偶被法院认定为失踪、无行为能力或被判处超过3年的刑期，配偶中的一方也可以提出离婚。',
      },
      {
        question: '在提交申请后的一个月内可以结婚吗？',
        answer:
          '可以，如果有合理的理由或特殊情况：怀孕、孩子出生、对其中一方生命的直接威胁等。',
      },
    ],

    [
      {
        question:
          '¿Es posible registrar un matrimonio con un extranjero en el consulado?',
        answer:
          'No, el consulado no registra matrimonios de ciudadanos rusos con ciudadanos de otros estados. Tales matrimonios deben ser concluidos en oficinas de registro civil en el territorio de Rusia o en departamentos similares en el extranjero.',
      },
      {
        question:
          '¿Puede uno de los cónyuges presentar una solicitud al consulado?',
        answer:
          'Sí, en este caso es posible emitir 2 solicitudes separadas, y la firma de la persona ausente debe ser notarizada. Ambos cónyuges deben comparecer para el procedimiento de registro del matrimonio.',
      },
      {
        question:
          '¿Puedo apostillar un documento de registro civil en el consulado?',
        answer:
          'No, el consulado no puede certificar con un apostilla los documentos emitidos en la Federación Rusa. Sin embargo, a través del consulado, puede solicitar un documento de registro civil repetido de Rusia que ya tenga un apostilla.',
      },
      {
        question: '¿Es posible divorciarse en el consulado?',
        answer:
          'Sí, si ambos cónyuges están de acuerdo en divorciarse y no tienen hijos menores en común o reclamaciones financieras entre sí, o existe una decisión de divorcio de un tribunal ruso. Además, uno de los cónyuges puede solicitar el divorcio si el otro cónyuge es declarado desaparecido, incompetente o condenado a más de 3 años de prisión.',
      },
      {
        question:
          '¿Es posible casarse antes de un mes después de presentar la solicitud?',
        answer:
          'Sí, si hay razones válidas o circunstancias especiales: embarazo, nacimiento de un hijo, amenaza inmediata a la vida de una de las partes, entre otros.',
      },
    ],
  ],

  faq: [
    {
      userName: [
        'Можно ли в консульстве зарегистрировать брак с иностранцем?',
        'Is it possible to register a marriage with a foreigner at the consulate?',
        '在领事馆可以与外国人登记结婚吗？',
        '¿Es posible registrar un matrimonio con un extranjero en el consulado?',
      ],
      desc: [
        [
          'Нет, консульство не регистрирует браки российских граждан с гражданами других государств.',
          'No, the consulate does not register marriages of Russian citizens with citizens of other states.',
          '不可以，领事馆不登记俄罗斯公民与其他国家公民的婚姻。',
          'No, el consulado no registra matrimonios de ciudadanos rusos con ciudadanos de otros estados.',
        ],
        [
          'Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
          'Such marriages should be concluded in civil registry offices on the territory of Russia, or in similar departments abroad.',
          '这类婚姻应在俄罗斯境内的民事登记处或在海外的类似部门进行。',
          'Tales matrimonios deben ser concluidos en oficinas de registro civil en el territorio de Rusia o en departamentos similares en el extranjero.',
        ],
      ],
    },
    {
      userName: [
        'Может ли заявление в консульство подать кто‑то один из супругов?',
        'Can one of the spouses submit an application to the consulate?',
        '夫妻中的一方可以向领事馆提交申请吗？',
        '¿Puede uno de los cónyuges presentar una solicitud al consulado?',
      ],
      desc: [
        [
          'Да, в таком случае возможно оформить 2 отдельных заявления, при этом подпись отсутствующего лица должна быть нотариально заверена.',
          'Yes, in this case it is possible to issue 2 separate applications, and the signature of the absent person must be notarized.',
          '可以，在这种情况下，可以提交两个单独的申请，缺席者的签名必须经过公证。',
          'Sí, en este caso es posible emitir 2 solicitudes separadas, y la firma de la persona ausente debe ser notarizada.',
        ],
        [
          'На саму процедуру регистрации брака должны явиться оба брачующихся.',
          'Both spouses must appear for the marriage registration procedure themselves.',
          '两个配偶必须亲自出席婚姻登记程序。',
          'Ambos cónyuges deben comparecer para el procedimiento de registro del matrimonio.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I affix an apostille to a registry office document at the consulate?',
        '我可以在领事馆对登记处的文件加盖公证吗？',
        '¿Puedo apostillar un documento de registro civil en el consulado?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
          'No, the consulate cannot certify the documents issued in the Russian Federation with an apostille. However, through the consulate you can request a duplicate of a civil registry office document from Russia with an apostille already affixed to it.',
          '不可以，领事馆不能对在俄罗斯联邦签发的文件加盖公证。然而，您可以通过领事馆请求从俄罗斯获取一份已加盖公证的重复登记处文件。',
          'No, el consulado no puede certificar los documentos emitidos en la Federación Rusa con un apostilla. Sin embargo, a través del consulado, puede solicitar un documento de registro civil repetido de Rusia que ya tenga un apostilla.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли в консульстве развестись?',
        'Is it possible to get a divorce at the consulate?',
        '在领事馆可以离婚吗？',
        '¿Es posible divorciarse en el consulado?',
      ],
      desc: [
        [
          'Да, если оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу, либо есть решение российского суда о разводе.',
          'Yes, if both spouses agree to divorce, and they do not have joint minor children or financial claims against each other, or there is a Russian court decision on divorce.',
          '可以，如果双方同意离婚，并且他们没有共同的未成年子女或财务索赔，或者有俄罗斯法院的离婚裁决。',
          'Sí, si ambos cónyuges están de acuerdo en divorciarse y no tienen hijos menores en común o reclamaciones financieras entre sí, o existe una decisión de divorcio de un tribunal ruso.',
        ],
        [
          'Также один из супругов может подать на развод, если второй супруг судом признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет.',
          'Also, one of the spouses can file for divorce if the second spouse is recognized by the court as missing, incompetent, or sentenced to a term of more than 3 years.',
          '此外，如果第二个配偶被法院认定为失踪、无行为能力或被判处超过3年的刑期，配偶中的一方也可以提出离婚。',
          'Además, uno de los cónyuges puede solicitar el divorcio si el otro cónyuge es declarado desaparecido, incompetente o condenado a más de 3 años de prisión.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли пожениться раньше, чем через месяц после подачи заявления?',
        'Is it possible to get married earlier than a month after submitting the application?',
        '在提交申请后的一个月内可以结婚吗？',
        '¿Es posible casarse antes de un mes después de presentar la solicitud?',
      ],
      desc: [
        [
          'Да, если на то есть уважительные причины или особые обстоятельства: беременность, рождение ребёнка, непосредственная угроза жизни одной из сторон и другие.',
          'Yes, if there are good reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
          '可以，如果有合理的理由或特殊情况：怀孕、孩子出生、对其中一方生命的直接威胁等。',
          'Sí, si hay razones válidas o circunstancias especiales: embarazo, nacimiento de un hijo, amenaza inmediata a la vida de una de las partes, entre otros.',
        ],
      ],
    },
  ],

  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Регистрация рождения ребёнка',
          "Registration of a child's birth.",
          '登记孩子的出生',
          'Registro del nacimiento de un niño.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Зарегистрировать рождение ребёнка в консульстве и оформить ему свидетельство о рождении возможно, только если оба родителя либо единственный родитель) являются гражданами России.',
          'It is possible to register the birth of a child at the consulate and issue a birth certificate only if both parents (or the sole parent) are citizens of Russia.',
          '只有当父母（或唯一的父母）是俄罗斯公民时，才能在领事馆注册孩子的出生并颁发出生证明。',
          'Es posible registrar el nacimiento de un niño en el consulado y emitir un certificado de nacimiento solo si ambos padres (o el único padre) son ciudadanos de Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация рождения возможна одновременно с подачей заявлений на оформление гражданства и паспорта ребёнку (такая возможность предусмотрена не во всех консульствах и зависит от страны обращения).',
          'Birth registration can be done simultaneously with the application for citizenship and passport for the child (this possibility is not available in all consulates and depends on the country of application).',
          '出生登记可以与为孩子申请国籍和护照同时进行（这种可能性并非在所有领事馆都适用，具体取决于申请国家）。',
          'La inscripción del nacimiento se puede realizar simultáneamente con la solicitud de ciudadanía y pasaporte para el niño (esta posibilidad no está disponible en todos los consulados y depende del país de solicitud).',
        ],
      },

      {
        type: 'paragraph',
        text: [
          'Для подачи заявления на оформление свидетельства о рождении необходимо личное присутствие одного из родителей, присутствие самого ребёнка и второго родителя не требуются.',
          'To submit an application for a birth certificate, the personal presence of one of the parents is required, the presence of the child and the second parent is not needed.',
          '申请出生证明时，要求至少有一位父母亲自到场，孩子和另一位父母的到场并不需要。',
          'Para presentar una solicitud de certificado de nacimiento, se requiere la presencia personal de uno de los padres; la presencia del niño y del segundo padre no es necesaria.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление должно быть подано не позднее чем через месяц со дня рождения ребёнка.',
          "The application must be submitted no later than one month after the child's birth.",
          '申请必须在孩子出生后的一个月内提交。',
          'La solicitud debe presentarse a más tardar un mes después del nacimiento del niño.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельство о рождении ребёнка российского образца выдаётся в день предоставления полного пакета документов в консульство.',
          'The Russian birth certificate is issued on the day the full set of documents is submitted to the consulate.',
          '俄罗斯式出生证明在提交完整文件材料当天发放。',
          'El certificado de nacimiento ruso se emite el mismo día en que se presenta el conjunto completo de documentos en el consulado.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Регистрация усыновления (удочерения)',
          'Registration of adoption.',
          '收养（领养）登记',
          'Registro de adopción.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Усыновители обязаны обратиться в консульство не позднее одного месяца со дня вступления в силу решения суда об усыновлении.',
          'Adopters are required to contact the consulate no later than one month from the date the court decision on adoption comes into force.',
          '收养人必须在法院关于收养的判决生效之日起一个月内与领事馆联系。',
          'Los adoptantes deben contactar al consulado a más tardar un mes después de que la decisión del tribunal sobre la adopción entre en vigor.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При составлении акта об усыновлении в консульстве определяется, какими станут фамилия и отчество усыновляемого, а также изменится ли имя, дата либо место рождения усыновляемого ребёнка, необходимо ли записать усыновителей в качестве родителей.',
          'When drawing up the adoption act at the consulate, it is determined what the surname and patronymic of the adopted child will be, and whether the name, date, or place of birth of the adopted child will change, and whether it is necessary to record the adopters as parents.',
          '在领事馆编写收养文书时，将确定被收养儿童的姓氏和父名，以及被收养儿童的名字、出生日期或出生地点是否会发生变化，以及是否有必要将收养人记录为父母。',
          'Al redactar el acta de adopción en el consulado, se determina cuál será el apellido y el patronímico del niño adoptado, si el nombre, la fecha o el lugar de nacimiento del niño adoptado cambiarán y si es necesario registrar a los adoptantes como padres.',
        ],
      },
      {
        type: 'collapsed',
        title: [
          'На основании решения суда',
          'On the basis of the court decision',
          '根据法院的裁决',
          'Sobre la base de la decisión del tribunal',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Может быть сделано устно или в письменной форме следующими лицами:',
              'May be done orally or in writing by the following persons:',
              '可以口头或书面形式由以下人员进行：',
              'Puede hacerse oralmente o por escrito por las siguientes personas:',
            ],
          },
          {
            type: 'list',
            items: [
              [
                'матерью или отцом ребёнка',
                'mother or father of the child',
                '孩子的母亲或父亲',
                'madre o padre del niño',
              ],
              [
                'опекуном (попечителем) ребёнка',
                'guardian (trustee) of the child',
                '孩子的监护人（受托人）',
                'tutor (fideicomisario) del niño',
              ],
              [
                'лицом, на иждивении которого находится ребёнок',
                'person of who a child is dependent on',
                '孩子的依赖人',
                'persona de la que depende el niño',
              ],
              [
                'самим ребёнком, достигшим совершеннолетия',
                'by the child himself who has reached the age of majority',
                '已经成年儿童本人',
                'por el propio niño que ha alcanzado la mayoría de edad',
              ],
            ],
          },
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Установление отцовства',
          'Establishment of Paternity',
          '确立父亲身份',
          'Establecimiento de la paternidad',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Оформление установления отцовства в консульстве возможно для граждан России на следующих основаниях:',
          'The establishment of paternity at the consulate is possible for Russian citizens on the following grounds:',
          '在领事馆确认父权对于俄罗斯公民是可能的，依据如下:',
          'El establecimiento de la paternidad en el consulado es posible para los ciudadanos rusos en las siguientes bases:',
        ],
      },
      {
        type: 'collapsed',
        first: true,
        title: [
          'Совместное заявление отца и матери',
          'The joint application of the father and mother',
          '父母共同申请',
          'La solicitud conjunta del padre y la madre',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Подаётся в случае, если родители ребёнка не состояли в браке на момент его рождения, при этом сведения об отце вносились со слов матери или в соответствующей графе был прочерк.',
              "Submitted in the case if the child's parents were not married at the time of birth, while the information about the father was entered based on the mother's words or the corresponding field was blank.",
              '如果孩子的父母在出生时没有结婚，且关于父亲的信息是根据母亲的陈述填写的，或者相应的字段是空白的，则提交。',
              'Se presenta en caso de que los padres del niño no estuvieran casados en el momento del nacimiento, mientras que la información sobre el padre se ingresó según las palabras de la madre o el campo correspondiente estaba en blanco.',
            ],
          },
          {
            type: 'paragraph',
            text: [
              'Заявление может быть подано во время регистрации рождения ребёнка, после него или во время беременности матери, если все заранее понимают, что после рождения ребёнка это будет сделать по каким-либо причинам затруднительно.',
              "The application can be submitted during the registration of the child's birth, after it, or during the mother's pregnancy if everyone understands in advance that it will be difficult to do so for any reason after the child's birth.",
              '申请可以在孩子出生登记时提交，之后或在母亲怀孕期间提交，如果大家事先了解，出生后因某些原因会很难这样做。',
              'La solicitud puede presentarse durante el registro del nacimiento del niño, después de él o durante el embarazo de la madre, si todos entienden de antemano que por alguna razón será difícil hacerlo después del nacimiento del niño.',
            ],
          },
          {
            type: 'paragraph',
            text: [
              'В случае, если свидетельство о рождении было оформлено более одного года назад, проводится дополнительная проверка с истребованием копии записи акта о рождении ребёнка. В данном случае срок предоставления услуги может составить до 1 года.',
              "If the birth certificate was issued more than a year ago, an additional check is carried out by requesting a copy of the child's birth record. In this case, the service delivery time may take up to 1 year.",
              '如果出生证明是在一年以上前颁发的，将通过要求提供孩子出生记录的副本进行额外检查。在这种情况下，提供服务的时间可能长达1年。',
              'Si el certificado de nacimiento se emitió hace más de un año, se lleva a cabo una verificación adicional solicitando una copia del registro de nacimiento del niño. En este caso, el tiempo de entrega del servicio puede tardar hasta 1 año.',
            ],
          },
          {
            type: 'paragraph',
            text: [
              'При наличии иностранного свидетельства о рождении — установление отцовства производится только в органах ЗАГС России или аналогичных ведомствах данного иностранного государства.',
              'If there is a foreign birth certificate, paternity is established only in the Civil Registry Office of Russia or similar agencies of the respective foreign state.',
              '如果有外国出生证明，则父亲身份的确认仅在俄罗斯的民事登记处或相关外国的类似机构进行。',
              'Si existe un certificado de nacimiento extranjero, la paternidad se establece solo en la Oficina de Registro Civil de Rusia o en agencias similares del respectivo estado extranjero.',
            ],
          },
        ],
      },
      {
        type: 'collapsed',
        title: [
          'По заявлению отца',
          'The application of the father',
          '父亲的申请',
          'La solicitud del padre',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Заявление отца, не состоящего в браке с матерью ребёнка на момент рождения ребёнка, подаётся в случае:',
              'The application of the father, not married to the child at the time of birth of the child, is submitted in the following cases:',
              '未在孩子出生时与孩子母亲结婚的父亲的申请，在以下情况下提交：',
              'La solicitud del padre, que no está casado con la madre del niño en el momento del nacimiento del niño, se presenta en los siguientes casos:',
            ],
          },
          {
            type: 'list',
            items: [
              [
                'смерти матери',
                'death of the mother',
                '母亲去世',
                'muerte de la madre',
              ],
              [
                'признания матери недееспособной',
                "mother's death is not indelible",
                '母亲被认定为无行为能力',
                'la madre fue reconocida como incapacitada',
              ],
              [
                'отсутствия сведений о её месте пребывания',
                'absence of information about her place of residence',
                '缺乏有关她居住地的信息',
                'falta de información sobre su lugar de residencia',
              ],
              [
                'лишения матери родительских прав',
                "absence of mother's parents rights",
                '母亲失去父母权利',
                'la madre perdió sus derechos parentales',
              ],
              [
                'согласия на установление отцовства органа опеки и попечительства',
                'consent to establish paternity of the guardianship and trusteeship authority',
                '监护和托管机构同意确立父权',
                'consentimiento para establecer la paternidad de la autoridad de tutela y custodia',
              ],
            ],
          },
        ],
      },

      {
        type: 'collapsed',
        title: [
          'На основании решения суда',
          'On the basis of the court decision',
          '根据法院的决定',
          'Sobre la base de la decisión del tribunal',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Может быть сделано устно или в письменной форме следующими лицами:',
              'May be done orally or in writing by the following persons:',
              '可以由以下人员口头或书面进行：',
              'Puede hacerse de forma oral o escrita por las siguientes personas:',
            ],
          },
          {
            type: 'list',
            items: [
              [
                'матерью или отцом ребёнка',
                'mother or father of the child',
                '孩子的母亲或父亲',
                'madre o padre del niño',
              ],
              [
                'опекуном (попечителем) ребёнка',
                'guardian (trustee) of the child',
                '孩子的监护人（受托人）',
                'tutor (curador) del niño',
              ],
              [
                'лицом, на иждивении которого находится ребёнок',
                'person of who a child is dependent on',
                '孩子的抚养人',
                'persona de la que depende el niño',
              ],
              [
                'самим ребёнком, достигшим совершеннолетия',
                'by the child himself who has reached the age of majority',
                '已满法定年龄的孩子本人',
                'por el propio niño que ha alcanzado la mayoría de edad',
              ],
            ],
          },
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Регистрация заключения брака',
          'Registration of marriage.',
          '婚姻登记',
          'Registro de matrimonio.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация брака в консульстве / посольстве производится только при личном присутствии лиц, вступающих брак, и только между гражданами РФ.',
          'Marriage registration at the consulate/embassy is carried out only in the presence of the persons getting married and only between citizens of the Russian Federation.',
          '婚姻登记仅在俄罗斯公民之间进行，并且必须亲自到场。',
          'El registro de matrimonio se realiza solo entre ciudadanos de la Federación Rusa y solo en presencia de las personas que se casan.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Консульство не регистрирует браки российских граждан с гражданами других государств. Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
          'The consulate does not register marriages between Russian citizens and citizens of other countries. Such marriages should be registered at the registry offices in Russia or in similar institutions abroad.',
          '领事馆不登记俄罗斯公民与其他国家公民的婚姻。这样的婚姻应在俄罗斯的登记机关或国外的类似机构注册。',
          'El consulado no registra matrimonios entre ciudadanos rusos y ciudadanos de otros países. Tales matrimonios deben ser registrados en las oficinas del registro en Rusia o en instituciones similares en el extranjero.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На подачу заявления приходят оба брачующихся, либо один из них с нотариально заверенным заявлением от второго.',
          'Both parties getting married must come to submit the application, or one of them with a notarized statement from the other.',
          '双方结婚的人必须亲自到场提交申请，或者其中一人带着另一人的公证声明。',
          'Ambas partes deben acudir para presentar la solicitud, o uno de ellos con una declaración notariada del otro.',
        ],
      },

      {
        type: 'paragraph',
        text: [
          'Заключение брака происходит по истечении 1 месяца после подачи заявления, однако данный срок может быть уменьшен или увеличен решением консульства при наличии уважительных причин или особых обстоятельств: беременности, рождения ребёнка, непосредственной угрозы жизни одной из сторон и других.',
          'The marriage is concluded 1 month after the application is submitted, but this period can be shortened or extended by the consulate for valid reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
          '婚姻在提交申请后一个月内完成，但在有合法理由或特殊情况下，例如怀孕、孩子出生、任何一方面临生命威胁等，领事馆可以缩短或延长该期限。',
          'El matrimonio se formaliza un mes después de la presentación de la solicitud, pero este plazo puede acortarse o extenderse por el consulado por razones válidas o circunstancias especiales: embarazo, nacimiento de un hijo, amenaza inminente para la vida de una de las partes, entre otros.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На саму процедуру регистрации заключения брака должны явиться лично оба брачующихся.',
          'Both parties getting married must personally attend the marriage registration procedure.',
          '双方结婚的人必须亲自到场进行婚姻登记手续。',
          'Ambas partes deben asistir personalmente al procedimiento de registro del matrimonio.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация расторжения брака', 'Registration of divorce.'],
      },
      {
        type: 'paragraph',
        text: [
          'Расторжения брака в консульстве возможно только между гражданами РФ в следующих случаях:',
          'Divorce at the consulate is possible only between Russian citizens in the following cases:',
          '在领事馆离婚只适用于俄罗斯公民，以下情况适用：',
          'El divorcio en el consulado es posible solo entre ciudadanos rusos en los siguientes casos:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу',
            'both spouses agree to divorce, and they have no common minor children or financial claims against each other',
            '双方同意离婚，并且没有共同的未成年子女或相互的经济索赔',
            'ambos cónyuges están de acuerdo en divorciarse, y no tienen hijos menores en común ni reclamaciones financieras entre sí',
          ],
          [
            'супруги уже имеют решение российского суда о расторжении брака',
            'the spouses already have a Russian court decision on divorce',
            '夫妻已经有俄罗斯法院的离婚裁决',
            'los cónyuges ya tienen una decisión judicial rusa sobre el divorcio',
          ],
          [
            'у одного из супругов есть решение (приговор) суда, в котором другой супруг признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет',
            'one of the spouses has a court decision (verdict) in which the other spouse is declared missing, incapacitated, or sentenced to a term of more than 3 years',
            '其中一方有法院判决（判决书），声明另一方失踪、无行为能力或被判刑超过3年',
            'uno de los cónyuges tiene una decisión judicial (veredicto) en la que el otro cónyuge es declarado desaparecido, incapaz o condenado a más de 3 años',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Расторжение брака происходит по истечении 1 месяца со дня подачи заявления, при наличии решения суда о разводе — в течение 10 дней.',
          'The divorce takes place 1 month after the application is submitted, and in the presence of a court decision on divorce — within 10 days.',
          '离婚在提交申请后一个月内完成，如有离婚判决则在10天内完成。',
          'El divorcio se realiza un mes después de presentar la solicitud y, en presencia de una decisión judicial sobre el divorcio, dentro de los 10 días.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация перемены имени', 'Registration of name change.'],
      },
      {
        type: 'paragraph',
        text: [
          'Любой гражданин России старше 14 лет имеет право поменять свои имя, фамилию и отчество.',
          'Any Russian citizen over the age of 14 has the right to change their first name, last name, and patronymic.',
          '任何超过14岁的俄罗斯公民都有权更改自己的名字、姓氏和父称。',
          'Cualquier ciudadano ruso mayor de 14 años tiene derecho a cambiar su nombre, apellido y patronímico.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок рассмотрения заявления — 1 месяц с момента подачи. По итогу выдаётся свидетельство о перемене имени, которое является основанием для подачи на замену всех основных российских документов: внутреннего и заграничного паспорта, свидетельств о рождении всех несовершеннолетних детей (при наличии) и других.',
          'The application processing time is 1 month from the date of submission. As a result, a name change certificate is issued, which serves as the basis for applying for the replacement of all main Russian documents: internal and foreign passports, birth certificates of all minor children (if any), and others.',
          '申请处理时间为提交后1个月。最终会发放一个姓名变更证书，该证书是申请更换所有主要俄罗斯文件的依据：内部和外国护照、所有未成年子女的出生证明（如有）等。',
          'El tiempo de procesamiento de la solicitud es de 1 mes a partir de la fecha de presentación. Como resultado, se emite un certificado de cambio de nombre, que sirve como base para solicitar el reemplazo de todos los documentos rusos principales: pasaportes internos y extranjeros, certificados de nacimiento de todos los hijos menores (si los hay) y otros.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Полный процесс замены документов занимает несколько месяцев, при этом оформление некоторых из них, например, внутреннего паспорта, возможно только на территории России.',
          'The complete process of replacing documents takes several months, while some documents, such as the internal passport, can only be processed within Russia.',
          '更换文件的完整过程需要几个月的时间，而其中一些文件，例如内部护照，只能在俄罗斯境内处理。',
          'El proceso completo de reemplazo de documentos toma varios meses, mientras que algunos documentos, como el pasaporte interno, solo pueden procesarse dentro de Rusia.',
        ],
      },

      {
        type: 'paragraph',
        text: [
          'При этом не стоит забывать, что помимо российских документов, необходимо будет вносить изменения и в заграничные. Стоит заранее изучить, что для этого потребуется и сможет ли консульство предоставить документы в требуемом формате.',
          'It should be remembered that, in addition to Russian documents, changes will also need to be made to foreign ones. It is worth researching in advance what will be required for this and whether the consulate can provide the documents in the required format.',
          '值得记住的是，除了俄罗斯文件外，还需要对外国文件进行更改。提前研究一下所需材料以及领事馆是否能够提供所需格式的文件。',
          'Cabe recordar que, además de los documentos rusos, también será necesario realizar cambios en los documentos extranjeros. Vale la pena investigar con anticipación qué se necesitará para esto y si el consulado puede proporcionar los documentos en el formato requerido.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Процедура и конкретные сроки получения новых документов зависят от страны консульства и индивидуальных обстоятельств гражданина. Мы проконсультируем вас, как лучше поступить в вашем конкретном случае, чтобы пройти этот процесс в минимально возможные сроки.',
          'The procedure and specific time frames for obtaining new documents depend on the country of the consulate and the individual circumstances of the citizen. We will advise you on the best course of action in your specific case to go through this process as quickly as possible.',
          '获取新文件的程序和具体时间框架取决于领事馆所在的国家以及公民的个人情况。我们将为您提供建议，以便在您的具体情况下尽可能快速地完成此过程。',
          'El procedimiento y los plazos específicos para obtener nuevos documentos dependen del país del consulado y de las circunstancias individuales del ciudadano. Le asesoraremos sobre la mejor manera de proceder en su caso específico para llevar a cabo este proceso lo más rápido posible.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация смерти', 'Registration of death.'],
      },
      {
        type: 'paragraph',
        text: [
          'Любые действия с телами российских граждан (если у умершего имелось только гражданство РФ), включая оформление документов, за рубежом производятся только на основании официального письма из посольства / консульства России. В этой связи в случае смерти гражданина России его родственникам настоятельно рекомендуется обратиться в консульство для получения консультации, координации своих действий, помощи в обращении в компанию ритуальных услуг и оформления документов.',
          'Any actions with the bodies of Russian citizens (if the deceased had only Russian citizenship), including the processing of documents, abroad are carried out only on the basis of an official letter from the Russian embassy/consulate. In this regard, in the event of the death of a Russian citizen, his relatives are strongly advised to contact the consulate for consultation, coordination of their actions, assistance in contacting the funeral services company, and paperwork.',
          '对俄罗斯公民的遗体进行的任何处理（如果死者只有俄罗斯国籍），包括在国外处理文件，仅根据俄罗斯大使馆/领事馆的正式信件进行。因此，俄罗斯公民去世时，强烈建议其亲属联系领事馆以获得咨询、协调其行动、协助联系殡葬服务公司和办理文件。',
          'Cualquier acción con los cuerpos de ciudadanos rusos (si el fallecido tenía solo la ciudadanía rusa), incluyendo el procesamiento de documentos, en el extranjero se lleva a cabo únicamente sobre la base de una carta oficial de la embajada/consulado ruso. En este sentido, en caso de muerte de un ciudadano ruso, se recomienda encarecidamente a sus familiares que se pongan en contacto con el consulado para recibir asesoramiento, coordinar sus acciones, ayudar a contactar a la empresa de servicios funerarios y tramitar la documentación.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация смерти производится по письменному или устному заявлению родственника умершего, любого лица, присутствовавшего в момент смерти, представителей медицинской организации, властей страны.',
          'Death registration is carried out based on a written or oral statement from a relative of the deceased, any person present at the time of death, representatives of a medical organization, or the authorities of the country.',
          '死亡登记是根据死者的亲属、在死亡时在场的任何人、医疗组织的代表或该国当局的书面或口头声明进行的。',
          'El registro de defunción se lleva a cabo en función de una declaración escrita o verbal de un familiar del fallecido, de cualquier persona presente en el momento de la muerte, de representantes de una organización médica o de las autoridades del país.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление о смерти должно быть сделано не позднее чем через 3 дня со дня наступления смерти либо обнаружения тела умершего.',
          'A death statement must be made no later than 3 days after the death or the discovery of the body of the deceased.',
          '死亡声明必须在死亡发生或发现死者遗体后的3天内提交。',
          'La declaración de defunción debe hacerse a más tardar 3 días después de la muerte o el descubrimiento del cuerpo del fallecido.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Повторная регистрация смерти и выдача соответствующей справки в консульстве не предусмотрена.',
          'Repeated death registration and the issuance of the corresponding certificate at the consulate are not provided.',
          '不提供重复的死亡登记和领事馆发放相应证明。',
          'No se prevé la repetición del registro de defunción ni la emisión del certificado correspondiente en el consulado.',
        ],
      },
    ],
  ],
  workflow: {
    requestServiceType: {
      title: [
        'Что необходимо зарегистрировать?',
        'What do you need to register?',
        '您需要注册什么？',
        '¿Qué necesita registrar?',
      ],
      options: serviceTypes,
      label: [
        'Выберите из списка',
        'Select from the list',
        '从列表中选择',
        'Seleccione de la lista',
      ],
    },
    requestMarriageStatusAtTheBirthTime: {
      title: [
        'Состояли ли родители ребёнка в браке на момент его рождения?',
        "Were the child's parents married at the time of birth?",
        '孩子的父母在出生时是否已婚？',
        '¿Estaban casados los padres del niño en el momento de su nacimiento?',
      ],
      options: [
        ['Да', 'Yes', '是', 'Sí'],
        [
          'Нет, но хотим оформить официальное отцовство',
          'No, but we want to establish official paternity',
          '不是，但我们想确立正式的父亲身份',
          'No, pero queremos establecer la paternidad oficial',
        ],
        [
          'Нет, у ребёнка единственный родитель',
          'No, the child has a single parent',
          '不，孩子只有一个父母',
          'No, el niño tiene un solo padre',
        ],
      ],
    },
    requestPaternityBase: {
      title: [
        'Что будет основанием для установления отцовства?',
        'What will be the basis for establishing paternity?',
        '什么将成为确定父亲身份的依据？',
        '¿Cuál será la base para establecer la paternidad?',
      ],
      options: [
        [
          'Совместное заявление обоих родителей, не состоящих в браке',
          'Joint statement of both parents not married to each other',
          '双方父母的共同声明，未婚',
          'Declaración conjunta de ambos padres que no están casados entre sí',
        ],
        [
          'Заявление отца, не состоящего в браке с матерью ребенка',
          "Statement of the father not married to the child's mother",
          '父亲的声明，未婚与孩子的母亲',
          'Declaración del padre no casado con la madre del niño',
        ],
        ['Решение суда', 'Court decision', '法院裁决', 'Decisión judicial'],
      ],
    },
    requestIsTheMarriageFirst: {
      title: [
        'Является ли этот брак первым для обоих будущих супругов?',
        'Is this the first marriage for both future spouses?',
        '这是两位未来配偶的第一次婚姻吗？',
        '¿Es este el primer matrimonio para ambos futuros cónyuges?',
      ],
      options: [
        ['Да', 'Yes', '是', 'Sí'],
        ['Нет', 'No', '不', 'No'],
      ],
    },
    requestCase: {
      title: [
        'Выберите ваш случай',
        'Select your case',
        '选择您的案例',
        'Seleccione su caso',
      ],
      options: [
        [
          'Мы оба согласны на развод, общих несовершеннолетних детей нет и финансовых претензий друг к другу не имеем',
          'We both agree to divorce, have no minor children, and have no financial claims against each other',
          '我们双方都同意离婚，没有未成年子女，也没有对彼此的财务要求',
          'Ambos estamos de acuerdo en divorciarnos, no tenemos hijos menores y no tenemos reclamaciones financieras entre nosotros',
        ],
        [
          'У нас есть решение суда о расторжении брака',
          'We have a court decision on the dissolution of the marriage',
          '我们有关于离婚的法院裁决',
          'Tenemos una decisión judicial sobre la disolución del matrimonio',
        ],
        [
          'У меня есть решение (приговор) суда о том, что мой супруг признан судом безвестно отсутствующим, недееспособным либо осуждён на срок свыше 3 лет',
          'I have a court decision (sentence) that my spouse is declared missing, incapacitated, or sentenced to more than 3 years',
          '我有法院的决定（判决），我的配偶被宣告失踪、无能力或被判刑超过3年',
          'Tengo una decisión judicial (sentencia) que declara a mi cónyuge como desaparecido, incapacitado o condenado a más de 3 años',
        ],
      ],
    },
  },
  priceText1: [
    'Стоимость услуги зависит от наличия в пакете документов заявителя документов на иностранном языке и будет определена после консультации с юристом.',
    'The cost of the service depends on the presence of documents in a foreign language in the applicant’s package of documents and will be determined after consultation with a lawyer.',
    '服务的费用取决于申请者文件包中是否有外文文件，具体费用将在与律师咨询后确定。',
    'El costo del servicio depende de la presencia de documentos en un idioma extranjero en el paquete de documentos del solicitante y se determinará después de la consulta con un abogado.',
  ],
}

export default vocabularyZags
