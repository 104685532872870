import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  LinearProgress,
  Stack,
  Autocomplete,
} from '@mui/material/'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import GlobalContext from '../../../../GlobalContext'
import leftArrow from '../../../../images/icon/arrow-left.png'
import crossClose from '../../../../images/icon/cross-close.png'
import vocabularyWorkflow from '../../../../vocabulary/vocabularyWorkflow'
import vocabularyPowerOfAttorney from '../../../../vocabulary/vocabularyPowerOfAttorney'

export default function DialogPoaWorkflow3({ setNextWindow }) {
  const theme = useTheme()
  const [selected, setSelected] = React.useState(false)
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)

  const listOfTrusters = vocabularyPowerOfAttorney.userTypes.map(
    val => val.userName[langSelected],
  )
  const [poatValue, setPoatValue] = React.useState([''])

  const updateItem = (index, newValue) => {
    const newItems = [...poatValue]

    newItems[index] = newValue

    setPoatValue(newItems)
  }

  const addItem = () => {
    setPoatValue(prev => [...prev, listOfTrusters[0]])
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 2,
              })
            }}
          >
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyPowerOfAttorney.whoGives[langSelected]}
        </Typography>
        {poatValue.map((val, index) => {
          return (
            <Autocomplete
              sx={{ mb: 3 }}
              key={index}
              value={val}
              onChange={(event, newValue) => {
                setSelected(true)

                if (newValue == null) {
                  updateItem(index, '')
                } else {
                  updateItem(index, newValue)
                }
              }}
              inputValue={val}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === null || newInputValue === '') {
                  updateItem(index, '')
                } else {
                  updateItem(index, newInputValue)
                }
              }}
              options={listOfTrusters}
              renderInput={params => (
                <TextField
                  sx={{
                    minWidth: 1,
                    height: 56,
                    mr: '16px',
                    borderRadius: 0,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      color: theme.palette.primary.onSurface,
                    },

                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                      {
                        color: theme.palette.primary.onSurfaceVariant,
                        opacity: 1,
                      },
                  }}
                  {...params}
                  label={
                    poatValue.length > 1 &&
                    vocabularyPowerOfAttorney.trustersList[index]
                      ? vocabularyPowerOfAttorney.trustersList[index][
                          langSelected
                        ]
                      : vocabularyPowerOfAttorney.issuerStatus[langSelected]
                  }
                />
              )}
            />
          )
        })}

        <Typography
          component='p'
          sx={{
            color: theme.palette.primary.primary,
            cursor: 'pointer',
            my: 3,
          }}
          variant='labelLarge'
          onClick={() => {
            addItem()
          }}
        >
          {vocabularyPowerOfAttorney.addMoreTruster[langSelected]}
        </Typography>
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={30}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='text'
            disabled={selected}
            size='medium'
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 4,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  whoGives: poatValue,
                  whomGives: null,
                  whatGives: null,
                  transferRights: null,
                  timePoa: null,
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            disabled={!selected}
            sx={{
              border: 'none',
              height: '40px',
              mr: '16px',
              '&.Mui-disabled': {
                border: 'none',
              },
            }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 4,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  whoGives: poatValue,
                  whomGives: null,
                  whatGives: null,
                  transferRights: null,
                  timePoa: null,
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.forward[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
