import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import {
  Divider,
  Box,
  List,
  ListItemIcon,
  Typography,
  Drawer,
  ListItemButton,
  Collapse,
  ListItem,
} from '@mui/material'
import chatOutlined from '../../../images/icon/chat-outlined.png'
import logoImage from '../../../images/logo-small.png'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import calendarOutlined from '../../../images/icon/calendar-outlined.png'
import askOutlined from '../../../images/icon/ask-outlined.png'
import forwardOutlined from '../../../images/icon/bar-arrow.png'
import { v4 as uuidv4 } from 'uuid'
import homeTwoTone from '../../../images/icon/home-two-tone.png'
import balanceOutlined from '../../../images/icon/balance-outlined.png'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../../vocabulary/vocabulary'
import GlobalContext from '../../../GlobalContext'

import documentBlack from '../../../images/icon/document-black.png'
import buildingImage from '../../../images/icon/building-black.png'
import marriageRingsImage from '../../../images/marriage-black.png'
import courtImage from '../../../images/icon/court-black.png'
import balanceImage from '../../../images/balance-black.png'
import passportImage from '../../../images/passport-black.png'
import stampImage from '../../../images/icon/stamp-black.png'

export default function LeftDrawerSmallScreen() {
  const navigate = useNavigate()
  const { langSelected, leftDrawerOpen, GlobalActions } =
    useContext(GlobalContext)

  const [expanded, setExpanded] = React.useState(false)

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  const theme = useTheme()
  const list = () => (
    <Box style={{ marginTop: '20px' }}>
      <ListItem>
        <Typography variant='titleSmall' sx={{ display: 'block' }}>
          CONSUL ONLINE
        </Typography>
      </ListItem>

      <List>
        {vocabulary.listDrawerLeft[langSelected]?.map((text, index) => (
          <ListItem key={uuidv4()}>
            {index !== 2 ? (
              <ListItemButton
                onClick={() => {
                  switch (index) {
                    case 0:
                      GlobalActions.setMenuSelected(0)
                      navigate('/')
                      break
                    case 1:
                      GlobalActions.setMenuSelected(1)
                      navigate('/about')
                      break
                    case 3:
                      GlobalActions.setMenuSelected(3)
                      navigate('/appointment')
                      break
                    case 4:
                      GlobalActions.setMenuSelected(4)

                      navigate(`${window.location.pathname}?open=chat`)

                      break
                    case 5:
                      GlobalActions.setMenuSelected(5)
                      navigate('/faq')
                      break
                    case 6:
                      GlobalActions.setMenuSelected(6)
                      navigate('/prices')
                      break

                    default:
                  }

                  GlobalActions.setDrawerOpen(false)
                  GlobalActions.setSubmenuSelected(false)
                }}
              >
                <ListItemIcon sx={{ minWidth: '24px', mr: '16px' }}>
                  {index === 0 ? (
                    <img src={homeTwoTone} alt='logo' width={'24px'} />
                  ) : null}
                  {index === 1 ? (
                    <img src={logoImage} alt='logo' width={'24px'} />
                  ) : null}
                  {index === 2 ? (
                    <img src={balanceOutlined} alt='logo' width={'24px'} />
                  ) : null}
                  {index === 3 ? (
                    <img src={calendarOutlined} alt='logo' width={'24px'} />
                  ) : null}
                  {index === 4 ? (
                    <img src={chatOutlined} alt='logo' width={'24px'} />
                  ) : null}

                  {index === 5 ? (
                    <img src={askOutlined} alt='logo' width={'24px'} />
                  ) : null}
                  {index === 6 ? (
                    <img src={forwardOutlined} alt='logo' width={'24px'} />
                  ) : null}
                </ListItemIcon>
                <Typography variant='labelLargeShrink'>{text}</Typography>
              </ListItemButton>
            ) : (
              <>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.primary.surface,
                  }}
                >
                  <ListItemButton
                    sx={{
                      width: '280px',
                      height: '56px',
                      justifyContent: 'space-between',
                    }}
                    onClick={handleExpanded}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {!expanded ? (
                        <ListItemIcon sx={{ minWidth: '24px', mr: '16px' }}>
                          <img src={balanceOutlined} alt='logo' width={24} />
                        </ListItemIcon>
                      ) : null}

                      <Typography variant='labelLargeShrink'>{text}</Typography>
                    </Box>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={expanded} timeout='auto' unmountOnExit>
                    {vocabulary.listDrawerLeftSubMenu[langSelected].map(
                      (textSub, index) => {
                        return (
                          <Box
                            key={uuidv4()}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            onClick={() => {
                              GlobalActions.setDrawerOpen(false)

                              switch (index) {
                                case 0:
                                  navigate('/poa')
                                  break
                                case 1:
                                  navigate('/passport')
                                  break
                                case 2:
                                  navigate('/consent')
                                  break
                                case 3:
                                  navigate('/certificates')
                                  break
                                case 4:
                                  navigate('/legal-entities')
                                  break
                                case 5:
                                  navigate('/citizenship')
                                  break
                                case 6:
                                  navigate('/zags')
                                  break
                                case 7:
                                  navigate('/other')
                                  break
                                case 8:
                                  navigate('/consulting')
                                  break
                                default:
                              }
                            }}
                          >
                            <ListItemButton
                              sx={{
                                width: '280px',
                                height: '56px',
                              }}
                            >
                              <ListItemIcon
                                sx={{ minWidth: '24px', mr: '16px' }}
                              >
                                {index === 0 ? (
                                  <img
                                    src={documentBlack}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 1 ? (
                                  <img
                                    src={passportImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 2 ? (
                                  <img
                                    src={documentBlack}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 3 ? (
                                  <img
                                    src={stampImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 4 ? (
                                  <img
                                    src={buildingImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 5 ? (
                                  <img
                                    src={passportImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 6 ? (
                                  <img
                                    src={marriageRingsImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 7 ? (
                                  <img
                                    src={courtImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                                {index === 8 ? (
                                  <img
                                    src={balanceImage}
                                    alt='logo'
                                    width={'24px'}
                                  />
                                ) : null}
                              </ListItemIcon>

                              <Typography
                                variant='labelLargeShrink'
                                component='p'
                              >
                                {textSub}
                              </Typography>
                            </ListItemButton>
                            {index === 8 ? <Divider sx={{ mb: 2 }} /> : null}
                          </Box>
                        )
                      },
                    )}
                  </Collapse>
                </Box>
              </>
            )}
            {index === 0 ? <Divider /> : null}
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <React.Fragment key={uuidv4()}>
      <Drawer
        anchor='left'
        PaperProps={{
          style: { backgroundColor: theme.palette.primary.surface },
        }}
        sx={{
          width: '304px',

          flexShrink: 0,
        }}
        open={leftDrawerOpen}
        onClose={() => {
          GlobalActions.setDrawerOpen(false)
        }}
      >
        {list('left')}
      </Drawer>
    </React.Fragment>
  )
}
