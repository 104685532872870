import { useTheme } from '@mui/material/styles'
import footerLine from '../../../images/footer-line.png'
import logoFooter from '../../../images/logo-footer.png'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../../../GlobalContext'
import vocabulary from '../../../vocabulary/vocabulary'
import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Robot, TelegramLogo, WhatsappLogo } from '@phosphor-icons/react'

export default function Footer947() {
  const theme = useTheme()
  const { langSelected } = useContext(GlobalContext)
  const navigate = useNavigate()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          width: 1,
          height: '5px',
          backgroundImage: `url(${footerLine})`,
          backgroundRepeat: 'repeat-x',
          backgroundSize: 'contain',
        }}
      />

      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          color: theme.palette.primary.footer,
          //borderTop:"1px thin "+theme.palette.primary.primary,
          // boxShadow:"7px 7px 7px 7px "+theme.palette.primary.primary,
        }}
      >
        <Box sx={{ width: 1 / 3, mt: '24px' }}>
          <img
            src={logoFooter}
            alt='logo'
            style={{ width: '123px', height: '140px' }}
          />
        </Box>
        <Box
          sx={{
            width: 1 / 3,

            mt: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='bodySmall'>
            © 2024 Consulate Lawyer Online
          </Typography>
          <Box sx={{ color: theme.palette.primary.onSurfaceVariant, mt: 1 }}>
            <TelegramLogo
              size={32}
              weight='thin'
              style={{ marginRight: '16px', cursor: 'pointer' }}
              onClick={() => {
                window.location.href = process.env.REACT_APP_TELEGRAM_CONNECTION
              }}
            />
            <WhatsappLogo
              size={32}
              weight='thin'
              style={{ marginRight: '16px', cursor: 'pointer' }}
              onClick={() => {
                window.location.href = process.env.REACT_APP_WHATSAPP_CONNECTION
              }}
            />
            <Robot
              size={32}
              weight='thin'
              style={{ marginRight: '16px', cursor: 'pointer' }}
              onClick={() => {
                navigate(`${window.location.pathname}?open=chat`)
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '24px',
            mb: '40px',
            width: 1 / 3,
          }}
        >
          {vocabulary.footerList[langSelected].map((text, index) =>
            index > 3 ? (
              <React.Fragment key={uuidv4()}>
                <Typography
                  variant='labelLarge'
                  sx={{ mb: '20px', cursor: 'pointer' }}
                  onClick={() => {
                    switch (index) {
                      case 4:
                        navigate('/user-agreement')
                        break
                      case 5:
                        navigate('/cookie-policy')
                        break
                      case 6:
                        navigate('/privacy-policy')
                        break
                    }
                  }}
                >
                  {text}
                </Typography>
              </React.Fragment>
            ) : null,
          )}
        </Box>
      </Box>
    </Box>
  )
}
