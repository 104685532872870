import React from 'react'
import { Box } from '@mui/material'
import leftArrow from '../../images/icon/arrow-left.png'
import crossClose from '../../images/icon/cross-close.png'

export const Header = ({ onClose, onBackward, step }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '76px',
      }}
    >
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={step === 0 ? onClose : onBackward}
      >
        <img src={leftArrow} alt='left arrow' width={'24px'} />
      </Box>

      <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
        <img src={crossClose} alt='cross' width={'24px'} />
      </Box>
    </Box>
  )
}
