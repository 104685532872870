import { v4 as uuid } from 'uuid'
import { useSearchParams } from 'react-router-dom'
import { useContext } from 'react'
import { search } from 'api/search'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { Header } from 'components/Workflow/WorkflowContainerHeader'
import { useEffect, useRef, useState } from 'react'
import { SearchResult } from './SearchResult'
import { vocabulary, titleByPath, options } from 'vocabulary/vocabularySearch'
import GlobalContext from 'GlobalContext'
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Autocomplete,
} from '@mui/material/'
import { useTheme } from '@mui/material/styles'

import { Search as SearchIcon } from '@mui/icons-material'
import { useOnInputValueChange } from './hooks/useOnInputValueChange'

export const SearchModal = () => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const isOpened = searchParams?.get('open') === 'search'
  const searchModalRef = useRef(null)
  const [searchResults, setSearchResults] = useState([])
  const { langSelected } = useContext(GlobalContext)

  searchModalRef.current?.focus()

  const [value, onInputValueChange] = useOnInputValueChange(searchParams.query)

  const handleClose = () => {
    const { open, ...restParams } = searchParams

    setSearchParams(restParams)
  }

  const updateSearchResults = async query => {
    const { searchResults } = await search(query, langSelected)

    setSearchResults(
      searchResults.map(searchResult => ({
        title:
          titleByPath[searchResult.link.to]?.[langSelected] ||
          vocabulary.page[langSelected],
        ...searchResult,
      })),
    )
  }

  useEffect(() => {
    if (value?.length > 1) {
      updateSearchResults(value)
    } else {
      setSearchResults([])
    }
  }, [value])

  return (
    <DialogModalWindow open={isOpened}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: '1 0 auto',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ mx: '16px' }}>
          <Header onClose={handleClose} onBackward={handleClose} />

          <Typography variant='titleLarge'>
            {vocabulary.searchResults[langSelected]}
          </Typography>

          <Autocomplete
            value={value}
            onChange={onInputValueChange}
            freeSolo
            id='searchInput'
            selectOnFocus
            clearOnBlur
            disablePortal
            options={options[langSelected]}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={
                  vocabulary.searchModalInputPlaceHolder[langSelected]
                }
                // variant='outlined'
                margin='dense'
                sx={{
                  minWidth: 1,
                  mt: 2,
                  borderRadius: 0,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    color: theme.palette.primary.onSurface,
                  },
                  '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                    color: theme.palette.primary.onSurfaceVariant,
                    opacity: 1,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
              width: 1,
              py: 2,
            }}
          >
            {searchResults.map((item, index) => (
              <SearchResult key={uuid()} {...item} />
            ))}
            {searchResults.length === 0
              ? vocabulary.notFound[langSelected]
              : null}
          </Box>
        </Box>
      </Box>
    </DialogModalWindow>
  )
}
