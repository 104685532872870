import React, { useContext, useState } from 'react'
import { Box, IconButton, MenuItem, Menu, Tooltip } from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import vocabulary from 'vocabulary/vocabulary'
import { Globe, List, Translate, User } from '@phosphor-icons/react'
import LoginFlow from '../LoginFlow/LoginFlow'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLoginFlowOpen } from 'pages/helpers/useLoginFlow'
import { postUserIp } from 'api/getIp'
import { SearchInput } from '../Search/SearchInput.phone'

import { Robot, TelegramLogo, WhatsappLogo } from '@phosphor-icons/react'

export default function HeaderMobile() {
  const { GlobalActions, leftDrawerOpen, langSelected, isLoggedIn } =
    useContext(GlobalContext)
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileCountryAnchorEl, setMobileCountryAnchorEl] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [countrySelected, setCountrySelected] = useState('')

  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setMobileCountryAnchorEl(null)
  }

  const handleCountryMenuOpen = event => {
    setMobileCountryAnchorEl(event.currentTarget)
  }

  const openLoginForm = useLoginFlowOpen('login')

  const menuId = 'primary-search-account-menu'
  const mobileMenuId = 'primary-search-account-menu-mobile'
  const countryMenuId = 'primary-search-account-menu-mobile'

  let countryRequest = async () => {
    let returnedCountry = await postUserIp()

    let index = vocabulary.countries.findIndex(country =>
      country.includes(returnedCountry),
    )

    if (vocabulary.euCountryIndexes.includes(index)) {
      await GlobalActions.setCurrency('eur')
    } else {
      GlobalActions.setCurrency('usd')
    }

    if (index !== -1) {
      setCountrySelected(vocabulary.countries[index][langSelected])
      GlobalActions.setCountry(returnedCountry)
    }
  }

  React.useEffect(() => {
    countryRequest()
  }, [langSelected])

  const filteredCountries = vocabulary.countries.filter(country =>
    country[langSelected]?.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  if (langSelected !== 0) {
    filteredCountries.sort((a, b) => {
      if (a[langSelected]?.toLowerCase() < b[langSelected].toLowerCase())
        return -1
      if (a[langSelected].toLowerCase() > b[langSelected].toLowerCase())
        return 1

      return 0
    })
  }

  const renderCountryMenu = (
    <Menu
      sx={{
        maxWidth: '90%',
        '& .MuiPaper-root': {
          maxHeight: 300, // Adjust as needed
          overflowY: 'auto',
          paddingRight: '10px', // Create space for the scrollbar
          '::-webkit-scrollbar': {
            width: '2px',
          },
        },
      }}
      anchorEl={mobileCountryAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={countryMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(mobileCountryAnchorEl)}
      onClose={handleMenuClose}
    >
      {filteredCountries.map((country, index) => (
        <MenuItem
          selected={country[langSelected] === countrySelected}
          key={index}
          sx={{ display: 'flex', alignItems: 'center' }}
          value={country[langSelected]}
          onClick={() => {
            setCountrySelected(country[langSelected])
            setSearchQuery('')
            handleMenuClose()
          }}
        >
          {country[langSelected]}
        </MenuItem>
      ))}
    </Menu>
  )

  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          GlobalActions.setLang(1)
          handleMenuClose()
        }}
      >
        English
      </MenuItem>
      <MenuItem
        onClick={() => {
          console.log('here')

          GlobalActions.setLang(0)

          handleMenuClose()
        }}
      >
        Русский
      </MenuItem>
      <MenuItem
        onClick={() => {
          GlobalActions.setLang(2)
          handleMenuClose()
        }}
      >
        中文
      </MenuItem>
      <MenuItem
        onClick={() => {
          GlobalActions.setLang(3)
          handleMenuClose()
        }}
      >
        Español
      </MenuItem>
    </Menu>
  )

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        zIndex: '999',
        width: '100%',
        backgroundColor:
          location.pathname === '/'
            ? theme.palette.primary.header
            : theme.palette.primary.secondaryContainer,
        display: 'flex',
        height: '48px',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={() => {
          GlobalActions.setDrawerOpen(!leftDrawerOpen)
        }}
        sx={{ ml: '8px' }}
      >
        <List size={24} weight='light' />
      </IconButton>
      <Box display='flex' alignItems='center' sx={{ ml: 2 }}>
        <Tooltip title='Telegram'>
          <IconButton
            onClick={() => {
              window.location.href = process.env.REACT_APP_TELEGRAM_CONNECTION
            }}
            aria-label='Connect via Telegram'
          >
            <TelegramLogo size={32} weight='regular' />
          </IconButton>
        </Tooltip>
        <Tooltip title='WhatsApp'>
          <IconButton
            onClick={() => {
              window.location.href = process.env.REACT_APP_WHATSAPP_CONNECTION
            }}
            aria-label='Connect via WhatsApp'
          >
            <WhatsappLogo size={32} weight='regular' />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <SearchInput />
        <IconButton
          aria-label='show 17 new notifications'
          color='inherit'
          sx={{ mx: '6px' }}
          aria-controls={countryMenuId}
          onClick={handleCountryMenuOpen}
        >
          <Globe size={24} weight='light' />
        </IconButton>

        <IconButton
          edge='end'
          aria-label='account of current user'
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleProfileMenuOpen}
          color='inherit'
          sx={{ mr: '6px' }}
        >
          <Translate size={24} weight='light' />
        </IconButton>
      </Box>

      <IconButton
        aria-label='show more'
        aria-controls={mobileMenuId}
        aria-haspopup='true'
        onClick={() => {
          if (!isLoggedIn) {
            openLoginForm()
          } else {
            navigate('/home')
          }
        }}
        color='inherit'
        sx={{ mr: '8px' }}
      >
        <User size={24} weight='light' />
      </IconButton>

      {renderCountryMenu}
      {renderLanguageMenu}
      <LoginFlow />
    </Box>
  )
}
